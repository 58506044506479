@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.compareing-product{
  position: fixed;
  width: 100%;
  height: 182px;;
  bottom: 0;
  background: $bgColor1E4;
  z-index: 1000;
  @include flex(row, center, center);
  .product-list{
    @include flex(row, center, center);
    .product-item{
      width: 230px;
      height: 134px;
      padding: 16px;
      box-sizing: border-box;
      margin-right: 24px;
      background-color: $bgColorFFF;
      &:last-child{
        margin-right: 0;
      }
      .select-item{
        width: 100%;
        height: 100%;
        @include flex(row, center, flex-start);
        .product-info{
          height: 100%;
          @include flex(row, flex-start, center);
          .product-cover{
            width: 40px;
            height: 24px;
          }
          p{
            @include ellipsis(6);
            width: 128px;
            margin-left: 8px;
            margin-right: 10px;
            line-height: 16px;
            font-size: $font16;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
          }
        }
        .btn-close{
          cursor: pointer;
          width: 12px;
          height: 12px;
        }
      }
      .normal-item{
        @include flex(row, center, center);
        width: 100%;
        height: 100%;
      }
    }
  }
  .now-compare{
    width: 230px;
    height: 134px;
    margin-left: 24px;
    @include flex(column, flex-start, center);
    font-size: $font20;
    font-family: $fontFamilySourceHanSansCNMedium;
    font-weight: 500;
    .compare-btn{
      cursor: pointer;
      width: 100%;
      height: 50px;
      background: $bgColorFFF;
      @include flex(row, center, center);
      img{
        width: 20px;
        height: 20px;
        margin-left: 8px;
        &.compare-animate{
          animation: turn 1s linear infinite;
        }
        &.compare-normal{
          display: none;
        }
      }
      span{
        height: 20px;
        line-height: 20px;
        color: $fontColor1E4;
      }
    }
    .reset-btn{
      cursor: pointer;
      margin-top: 32px;
      height: 19px;
      line-height: 19px;
      color: $fontColorFFF;
    }
  }
}
@keyframes turn{
  0%{-webkit-transform:rotate(0deg);}
  25%{-webkit-transform:rotate(90deg);}
  50%{-webkit-transform:rotate(180deg);}
  75%{-webkit-transform:rotate(270deg);}
  100%{-webkit-transform:rotate(360deg);}
}