@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.travel-process{
  @include flex(column, flex-start, flex-start);
  .instranue-banner{
    width: 100%;
    height: 320px;
    // object-fit: cover;
  }
  .form-conteiner{
    width: 1272px;
    margin: 0 auto;
    padding: 40px 88px 64px;
    box-sizing: border-box;
    background: $bgColorFFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    .top-box{
      span{
        height: 31px;
        font-size: $font24;
        font-family: $fontFamilyMicrosoftYaHei;
        color: $fontColor333;
        line-height: 31px;
      }
      .purchase-reason{
        @include flex(row, flex-start, center);
        cursor: pointer;
        img{
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
      }
      .insurance-plan{
        margin-top: 64px;
        @include flex(row, flex-start, center);
        .plan-item{
          cursor: pointer;
          position: relative;
          padding: 16px 40px;
          box-sizing: border-box;
          border: 1px solid #D3D3D3;
          margin-right: 72px;
          &.plan-item-select{
            border: 2px solid #447EDA;
            img{
              display: block;
            }
          }
          span{
            font-family: $fontFamilyMicrosoftYaHeiBold;
            font-weight: bold;
          }
          img{
            display: none;
            position: absolute;
            width: 40px;
            height: 32px;
            right: 0;
            bottom: 0;
          }
        }
      }
      .applicant-type{
        margin-top: 64px;
        @include flex(row, flex-start, center);
        .applicant-item{
          cursor: pointer;
          @include flex(row, flex-start, center);
          margin-right: 72px;
          img{
            width: 32px;
            height: 32px;
            margin-right: 16px;
          }
          span{
            font-family: $fontFamilyMicrosoftYaHeiBold;
            font-weight: bold;
          }
        }
      }
    }
    .label-box{
      width: 100%;
      @include flex(row, flex-start, center);
      margin-bottom: 16px;
      .label-desc{
        position: relative;
        margin-right: 6%;
        width: 47%;
        @include flex(row, flex-start, center);
        &:last-child{
          margin-right: 0;
        }
        span{
          height: 26px;
          font-size: $font20;
          color: $fontColor333;
          line-height: 26px;
          &.label-select{
            &::after{
              content: '*';
              color: $fontColorFF0;
            }
          }
        }
      }
    }
    .title-box{
      margin: 72px 0 24px;
      width: 100%;
      @include flex(row, flex-start, center);
      span{
        height: 52px;
        font-size: $font40;
        font-weight: bold;
        color: $fontColor1E4;
        line-height: 52px;
      }
      div{
        flex: 1;
        width: 100%;
        margin-left: 32px;
        height: 2px;
        background: $bgColor1E4;
      }
    }
    .traveler-intro{
      margin-top: 8px;
      background: $bgColorFDF;
      border-radius: 8px;
      padding: 24px;
      font-size: $font24;
      font-family: $fontFamilyMicrosoftYaHei;
      color: $fontColor333;
      line-height: 31px;
    }
    .table-container{
      width: 100%;
      margin-top: 72px;
      @include flex(column, flex-start, flex-start);
      .table-header{
        width: 100%;
        @include flex(row, flex-start, flex-start);
        &.table-header-trip{
          border-bottom: 1px solid #D3D3D3;
          justify-content: space-between;
          .header-item:first-child{
            border-bottom: none;
          }
          .header-item:nth-child(2n){
            display: none;
          }
        }
        .header-item{
          @include flex(column, center, center);
          cursor: pointer;
          height: 159px;
          border-radius: 8px 8px 0px 0px ;
          border: 1px solid #F2A915;
          background: $bgColorFFF;
          &.header-item-select{
            position: relative;
            background: $bgColor447;
            span{
              color: $fontColorFFF;
            }
            &::after{
              content: '';
              position: absolute;
              z-index: 10;
              top: 158px;
              left: 0;
              right: 0;
              height: 447px;
              border: 1px solid #F2A915;
              border-top: none;
              background: transparent;
            }
          }
          &:first-child{
            border: none;
            border-bottom: 1px solid #D3D3D3;
            align-items: flex-start;
            pointer-events: none;
            span{
              margin-top: 50px;
              margin-left: 0;
              color: $fontColor1E4;
            }
          }
          img{
            margin-top: 24px;
            width: 82px;
            height: 25px;
          }
          span{
            margin-top: 8px;
            height: 31px;
            font-size: $font24;
            font-family: $fontFamilyMicrosoftYaHeiBold;
            font-weight: bold;
            color: $fontColor333;
            line-height: 31px;
            white-space: pre-line;
          }
          button{
            text-align: center;
            margin-top: 16px;
            width: 224px;
            height: 47px;
            background: $bgColor447;
            border-radius: 8px;
            padding: 0;
            span{
              margin: 0;
              height: 47px;
              color: $fontColorFFF;
              line-height: 47px;
            }
          }
        }
      }
      .table-content{
        width: 100%;
        span{
          height: 26px;
          font-size: 20px;
          font-family: $fontFamilyMicrosoftYaHeiBold;
          font-weight: bold;
          color: $fontColor333;
          line-height: 26px;
        }
        .table-item{
          @include flex(row, flex-start, center);
          padding: 16px 0;
          background: $bgColorFAF;
          border: 1px solid #D3D3D3;
          &.table-item-trip{
            justify-content: space-between;
            .premium-box{
              &:nth-child(2n){
                display: none;
              }
            }
          }
          &:nth-child(2n){
            border: none;
            background: $bgColorFFF;
          }
          .premium-box{
            width: 23%;
            @include flex(column, center, center);
            &:first-child{
              width: 31%;
              align-items: flex-start;
              span{
                font-family: $fontFamilyMicrosoftYaHeiBold;
                font-weight: bold;
                color: $fontColor1E4;
                margin-left: 16px;
              }
            }
            :first-child{
              font-family: $fontFamilyMicrosoftYaHei;
              font-weight: normal;
            }
          }
          a{
            color: $fontColor1E4;
            font-family: $fontFamilyMicrosoftYaHei;
            font-weight: normal;
            text-decoration: underline;
          }
        }
      }
      .featured-plan{
        margin-top: 32px;
        width: 100%;
        @include flex(row, flex-start, flex-start);
        span{
          margin-left: 16px;
          height: 26px;
          font-size: $font20;
          font-family: $fontFamilyMicrosoftYaHeiBold;
          font-weight: bold;
          color: $fontColor333;
          line-height: 26px;
        }
        .left-plan-title{
          @include flex(column, flex-start, flex-start);
          width: 50%;
          background: $bgColorF0F;
          li{
            width: 100%;
            @include flex(row, flex-start, center);
            height: 74px;
            &:first-child{
              background: $bgColor1E4;
              span{
                color: $fontColorFFF;
              }
            }
          }
        }
        .right-plan-content{
          @include flex(column, flex-start, flex-start);
          width: 50%;
          background: $bgColorFFF;
          li{
            width: 100%;
            @include flex(row, flex-start, center);
            height: 74px;
            border-left: 2px solid #1E4994;
            border-bottom: 1px solid #D3D3D3;
            border-right: 1px solid #D3D3D3;
            span{
              margin-left: 0;
              padding-left: 16px;
              width: 33.3%;
              font-family: $fontFamilyMicrosoftYaHei;
              font-weight: normal;
            }
            &:first-child{
              background: $bgColorF0F;
              border-bottom: 2px solid #1E4994;
              span{
                text-align: center;
                width: 100%;
                padding-left: 0;
              }
            }
          }
        }
        .extra-protection{
          @include flex(column, flex-start, flex-start);
        }
      }
      .extra-protection{
        width: 100%;
        @include flex(column, flex-start, flex-start);
        .protection-list{
          .protection-item{
            margin: 24px 0;
            p, span{
              height: 31px;
              font-size: $font24;
              font-family: $fontFamilyMicrosoftYaHeiBold;
              font-weight: bold;
              color: $fontColor0FF;
              line-height: 31px;
            }
            p{
              margin-bottom: 8px;
            }
            .protection-intro{
              margin: 8px 0;
              @include flex(row, flex-start, center);
              img{
                width: 32px;
                height: 32px;
                margin-right: 16px;
              }
              .protection-desc{
                color: $fontColor333;
                font-family: $fontFamilyMicrosoftYaHei;
                font-weight: normal;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    .prompt-box{
      @include flex(column, flex-start, flex-start);
      margin-top: 24px;
      background: $bgColorE1E;
      border-radius: 8px;
      padding: 16px 24px;
      box-sizing: border-box;
      span{
        font-size: $font20;
        font-family: $fontFamilyMicrosoftYaHei;
        color: $fontColor1E4;
        line-height: 30px;
      }
      .prompt-item{
        @include flex(row, flex-start, flex-start);
        margin: 8px 0;
        .prompt-title{
          font-family: $fontFamilyMicrosoftYaHeiBold;
          font-weight: bold;
        }
        a{
          font-family: $fontFamilyMicrosoftYaHeiBold;
          font-weight: bold;
          text-decoration: underline;
          color: $fontColor1E4;
        }
      }
    }
    :global{
      .ant-form{
        .ant-select-selector{
          height: 63px;
        }
        .ant-select-selection-item{
          line-height: 63px;
          font-size: $font24;
          font-weight: bold;
          color: $fontColor333;
        }
        .ant-select-arrow{
          width: 40px;
          height: 40px;
          right: 8px !important;
          top: 16.5px !important;
        }
        .ant-picker{
          width: 100%;
          height: 63px;
        }
        .ant-input-group-addon{
          padding: 0;
        }
        .ant-form-item-explain-error{
          height: 26px;
          font-size: $font20;
          color: $fontColorFF0;
          line-height: 26px;
          margin-bottom: 24px;
        }
        .ant-input[disabled] {
          background: #F0F0F0;
        }
        .ant-picker-disabled{
          background: #F0F0F0;
        }
        .ant-select-selection-placeholder{
          height: 63px;
          font-size: $font24;
          color: $fontColor9D9;
          line-height: 63px;
          font-weight: 400;
        }
        input{
          -webkit-appearance: none;
          font-size: $font24;
          font-weight: 400;
          height: 63px;
          font-weight: bold;
          color: $fontColor333;
          &::-webkit-input-placeholder{
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
          &::-moz-placeholder{ 
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
          &:-ms-input-placeholder {
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .submit-btn{
    @include flex(row, flex-end, center);
    width: 1272px;
    margin: 64px auto 88px;
    button{
      width: 320px;
      height: 79px;
      font-size: $font24;
      text-align: center;
      border-radius: 4px;
      color: $fontColorFFF;
      background: $bgColor447;
    }
  }
  .loading-box{
    @include flex(column, center, center);
    position: fixed;
    z-index: 10;
    top: 112px;
    width: 100%;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
  }
}