@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.exclusive-container{
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  .space-div {
    height: 80px;
    line-height: 80px;
  }
}
