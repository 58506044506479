@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.payment-result{
  .top-header{
    @include flex(row, center, center);
    width: 100%;
    background: $bgColorFDF;
    padding: 24px 0 56px;
    box-sizing: border-box;
    .header-item{
      @include flex(column, center, center);
      position: relative;
      &::before{
        content: '投保詳情';
        position: absolute;
        width: 100px;
        top: 40px;
        height: 24px;
        font-size: $font18;
        color: $fontColor333;
        line-height: 24px;
        text-align: center;
      }
      &:first-child{
        &::before{
          content: '即時報價';
        }
      }
      &.header-item-preview{
        &::before{
          content: '預覽及付款';
        }
      }
      &:last-child{
        &::before{
          content: '確認';
        }
      }
      .receive-status{
        @include flex(column, center, center);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $bgColor1E4;
        &.receive-status-n{
          background: $bgColor9D9;
        }
        img{
          width: 20px;
          height: 20px;
        }
        span{
          height: 24px;
          font-size: $font18;
          color: $fontColor333;
          line-height: 24px;
          font-weight: bold;
          color: $fontColorFFF;
        }
      }
    }
    .progress-line{
      width: 291px;
      height: 2px;
      background: $bgColor1E4;
    }
  }
  .payment-result-body {
    width: 1272px;
    margin: 0 auto 88px;
    color: #333;
    &-content {
      padding: 64px 176px;
      text-align: center;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    }
    .payment-result-icon {
      img {
        width: 120px;
        height: 120px;
      }
    }
    .payment-result-thanks {
      margin-top: 40px;
      font-size: 24px;
    }
    .payment-result-number {
      font-size: 20px;
      margin-top: 12px;
    }
    .payment-result-phone {
      font-size: 20px;
      margin-top: 4px;
    }
    .payment-result-tips {
      margin-top: 40px;
      padding: 32px 24px;
      background: #E1EAF9;
      color: #1E4994;
      font-size: 18px;
      text-align: left;
      >div {
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    .pay-fail {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      margin-top: 36px;
    }
    .tobuy-another-policy {
      padding: 0 56px;
      display: inline-block;
      height: 58px;
      line-height: 58px;
      border-radius: 4px;
      border: 1px solid #447EDA;
      font-size: 20px;
      color: #447EDA;
      margin: 40px auto 0;
      cursor: pointer;
    }
  }
}