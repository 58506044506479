@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.modal-dialog{
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  @include flex(column,center, center);
  .modal-container{
    width: 100%;
    max-height: 90%;
    @include flex(column,center, center);
    position: relative;
    .modal-content{
      width: 1246px;
      height: 100%;
      background: $bgColorFFF;
      overflow-x: hidden;
      overflow-y: auto;
      .modal-header{
        z-index: 101;
        width: 1246px;
        top: 0;
        left: calc(50vw - 623px);
        @include flex(row, flex-end, center);
        cursor: pointer;
        position: absolute;
        img{
          margin-top: 25px;
          margin-right: 25px;
        }
      }
      .modal-body{
        display: flex;
        flex-direction: column;
        .container-product{
          position: absolute;
          z-index: 100;
          width: 1246px;
          height: 199px;
          top: 0;
          left: calc(50vw - 623px);
          @include flex(row, flex-start, center);
          padding: 41px 16px 24px;
          box-sizing: border-box;
          background-color: $bgColorFFF;
          .product-type{
            cursor: pointer;
            @include flex(column, center, center);
            width: 230px;
            height: 134px;
            background: $bgColorFFF;
            text-align: center;
            font-size: $font20;
            line-height: 22px;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
          }
          .product-list{
            @include flex(row, center, center);
            .product-item{
              display: flex;
              flex-direction: column;
              width: 230px;
              height: 134px;
              margin-left: 8px;
              .select-item{
                .close-btn{
                  cursor: pointer;
                  margin-top: 16px;
                  margin-right: 16px;
                  height: 12px;
                  text-align: right;
                }
                .name{
                  @include ellipsis(2);
                  height: 52px;
                  text-align: center;
                  margin-top: 4px;
                  line-height: 26px;
                  font-size: $font20;
                  font-family: $fontFamilySourceHanSansCNRegular;
                  font-weight: 400;
                  color: $fontColor333;
                }
                .check-btn{
                  cursor: pointer;
                  display: inline-block;
                  margin-top: 4px;
                  width: 100%;
                  height: 46px;
                  background: $bgColor447;
                  line-height: 46px;
                  text-align: center;
                  font-size: $font14;
                  font-family: $fontFamilySourceHanSansCNMedium;
                  font-weight: 500;
                  color: $fontColorFFF;
                  a {
                    display: block;
                    color: $fontColorFFF;
                    &:hover {
                      color: $fontColorFFF;
                    }
                  }
                }
              }
              .normal-item{
                cursor: pointer;
                @include flex(row, center, center);
                width: 100%;
                height: 100%;
                span{
                  width: 120px;
                  border: 2px solid #1E4994;
                  line-height: 46px;
                  text-align: center;
                  font-size: $font14;
                  font-family: $fontFamilySourceHanSansCNMedium;
                  font-weight: 500;
                  color: $fontColor1E4;
                }
              }
            }
          }
        }
        .scroll-view{
          margin-top: 199px;
          display: flex;
          flex-direction: column;
          padding: 0 16px 40px;
          box-sizing: border-box;
          .product-detail{
            display: flex;
            flex-direction: column;
            .menu-list{
              display: flex;
              flex-direction: column;
              margin-bottom: 10px;
              .menu-title{
                cursor: pointer;
                @include flex(row, space-between, center);
                height: 48px;
                background: $bgColorFAF;
                border: 1px solid #D3D3D3;
                padding: 0 16px;
                box-sizing: border-box;
                p{
                  height: 20px;
                  line-height: 20px;
                  font-size: $font20;
                  font-family: $fontFamilySourceHanSansCNRegular;
                  font-weight: 400;
                  color: $fontColor333;
                }
                img{
                  transition: all 0.3;
                  transform: rotate(0);
                  &.arrow-animate{
                    transform: rotate(180deg);
                    transition: all 0.3;
                  }
                }
              }
              ul{
                background-color: $bgColorFFF;
                li{
                  .insured-amount-box{
                    padding: 20px 0;
                    @include flex(row, flex-start, center);
                    p{
                      word-break: break-all;
                      padding-left: 16px;
                      width: 230px;
                      // height: 16px;
                      // line-height: 16px;
                      font-size: $font16;
                      font-family: $fontFamilySourceHanSansCNRegular;
                      font-weight: 400;
                      color: $fontColor333;
                    }
                    .insured-amount{
                      @include flex(row, flex-start, center);
                      span{
                        word-break: break-all;
                        margin-left: 8px;
                        width: 230px;
                        text-align: center;
                        // height: 14px;
                        // line-height: 14px;
                        font-size: $font16;
                        font-family: $fontFamilySourceHanSansCNRegular;
                        font-weight: 400;
                        color: $fontColor333;
                      }
                    }
                  }
                }
              }
            }
          }
          .remark-box{
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            .remark-title{
              margin-top: 9px;
              height: 16px;
              line-height: 16px;
              font-size: $font16;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor9D9;
            }
            .remark-content{
              margin-top: 14px;
              line-height: 22px;
              font-size: $font14;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor9D9;
            }
          }
        }
      }
    }
  }
}