@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.product-container{
  background-color: $bgColorEBE;
  .banner-box{
    @include backgroundImg(100%, 100%, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_banner_product_presentation.png', cover);
    @include flex(column, center, flex-start);
    height: 320px;
    padding-left: calc(100vw/2 - 624px);
    .title{
      width: 1272px;
      height: 49px;
      line-height: 49px;
      font-size: $font50;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      color: $fontColorFFF;
    }
    .desc{
      margin-top: 24px;
      width: 608px;
      line-height: 23px;
      font-size: $font20;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColorFFF;
    }
  }
  .title-content-box {
    @include flex(column, flex-start, center);
    font-family: $fontFamilySourceHanSansCNRegular;
    color: $fontColorFFF;
    background-color: $bgColor1E4;
    width: 100%;
    margin-bottom: -2px;
    .title-content {
      width: 1272px;
      @include flex(row, flex-start, center);
      height: 91px;
      line-height: 91px;
      span {
        padding-right: 15px;
      }
      .title-link {
        cursor: pointer;
      }
    }
  }
  .title-tab-box {
    @include flex(column, flex-start, center);
    font-family: $fontFamilySourceHanSansCNRegular;
    color: $fontColorFFF;
    background-color: $bgColor1E4;
    width: 100%;
    z-index: 10;
    position: sticky;
    top: 122px;
    .tab-box{
      width: 1272px;
      @include flex(row, flex-start, center);
      .tab-item{
        cursor: pointer;
        @include flex(column, center, center);
        width: 200px;
        height: 80px;
        line-height: 80px;
        &.tab-item-active{
          border-top: 4px solid $bgColorFCE;
          width: 200px;
          background-color: $bgColorFFF;
          color: $fontColor1E4;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
        }
      }
      .immediate-insurance-box {
        position: absolute;
        margin-left: 1062px;
        width: 200px;
        .immediate-insurance-item {
          cursor: pointer;
          line-height: 70px;
          height: 70px;
          width: 200px;
          background: $bgColorFCE;
          border-radius: 36px;
          color: $fontColor1E4;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
          @include flex(row, center, center);
          img {
            margin-left: 9px;
            margin-top: 2px;
          }
        }
      }
    }
  }
  .product-detail-container {
    @include flex(row, center, center);
    padding: 60px 0 80px 0;
    .product-detail-box {
      width: 1272px;
      @include flex(column, flex-start, flex-start);
      background: $bgColorFFF;
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.15);
      padding: 79px 80px;
      color: $fontColor333;

      // 保障詳情
      .guarantee-detail-box {
        // display: none;
        .guarantee-detail-title {
          width: 100%;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
        }
        .guarantee-detail-desc {
          width: 100%;
          margin: 24px 0 18px 0;
          font-family: $fontFamilySourceHanSansCNRegular;
          @include flex(row, flex-start, center);
          flex-wrap: wrap;
          .guarantee-detail-desc-item {
            @include flex(row, center, center);
            height: 66px;
            background: $bgColorF6F;
            border: 3px solid $bgColorE3E;
            border-radius: 34px;
            margin-right: 24px;
            margin-bottom: 20px;
            padding: 0 20px;
            img {
              margin-right: 7px;
            }
          }
        }
        .guarantee-detail-table {
          border: 3px solid $bgColor8DB;
          thead tr {
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
            text-align: center;
            background-color: $bgColorE1E;
            td:nth-child(1) {
              width: 386px;
              height: 80px;
            }
            td:nth-child(2) {
              width: 720px;
              border-left: 3px solid $bgColor8DB;
              border-right: 3px solid $bgColor8DB;
            }
          }
          tbody tr {
            td {
              border-bottom: 3px solid $bgColor8DB;
              padding: 16px;
            }
            td:nth-child(2) {
              border-left: 3px solid $bgColor8DB;
              border-right: 3px solid $bgColor8DB;
            }
          }
          .guarantee-detail-fst-cell {
            @include flex(row, flex-start, center);
            width: 100%;
            .guarantee-detail-fst-cell-item {
              display: grid;
              margin-left: 16px;
              span:first-child {
                font-family: $fontFamilySourceHanSansCNBold;
                font-weight: bold;
                margin-bottom: 8px;
              }
            }
          }
        }
        .guarantee-detail-other-table {
          border-top: 0 solid;
        }
      }

      //產品解讀, 典型案例
      .product-introduce-box {
        // display: none;
        width: 1110px;
        display: block;
        img, a img{
          border:0;
          margin:0;
          padding:0;
          max-width:1110px;
        }
      }

      // 投保須知
      .insurance-policy-box {
        // display: none;
        .insurance-policy-table {
          border: 3px solid $bgColor8DB;
          margin: 0;
          padding: 0;
          width: 100%;
          thead tr {
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
            background-color: $bgColorE1E;
            td {
              padding: 16px;
              height: 80px;
            }
          }
          tbody tr {
            td {
              border-bottom: 3px solid $bgColor8DB;
              padding: 16px;
            }
            .insurance-policy-one-cell {
              width: 100%;
              p:first-child {
                height: 50px;
                line-height: 50px;
              }
            }
          }
        }
      }

      // 理賠指引
      .claims-settlement-box {
        // display: none;
        width: 100%;
        .claims-settlement-top {
          border: 3px solid $bgColor8DB;
          .claims-settlement-flow {
            @include flex(row, center, center);
            padding: 50px 0px;
            .claims-settlement-flow-item {
              @include flex(row, center, center);
              .flow-item {
                @include flex(column, center, center);
                img {
                  padding: 0 40px;
                }
                span {
                  width: 185px;
                  text-align: center;
                  margin-top: 18px;
                  font-family: $fontFamilySourceHanSansCNBold;
                  font-weight: bold;
                }
              }
              .flow-next {
                width: 70px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: $fontColorD3D;
              }
            }
          }
          .claims-settlement-content {
            @include flex(column, center, flex-start);
            border-top: 3px solid $bgColor8DB;
            padding: 40px 16px;
            background-color: $bgColorE1E;
          }
        }
        .claims-settlement-bottom {
          .claims-settlement-title {
            margin-top: 38px;
            margin-bottom: 10px;
          }
          .claims-settlement-line {
            margin-bottom: 10px;
          }
          .claims-settlement-desc {
            padding: 0px 16px;
          }
        }
      }

      //常見問題
      .fqa-box {
        // display: none;
        .fqa-q {
          @include flex(row, flex-start, center);
          height: 50px;
          line-height: 50px;
          margin-bottom: 18px;
          span {
            padding-left: 16px;
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
          }
        }
        .fqa-a {
          @include flex(row, flex-start, flex-start);
          span {
            padding-left: 16px;
          }
        }
        .fqa-line {
          margin: 35px 0;
          width: 100%;
        }
      }
    }
  }
}