@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.phone-prefix{
  height: 61px;
  line-height: 61px;
  font-size: $font24;
  font-weight: bold;
  color: $fontColor333;
}
:global{
  .ant-form-item-control-input{
    position: relative;
    .send-code-btn{
      cursor: pointer;
      position: absolute;
      z-index: 100;
      top: 8px;
      right: 8px;
      text-align: center;
      width: 177px;
      height: 47px;
      background: $bgColor447;
      border-radius: 4px;
      border: 1px solid #D3D3D3;
      line-height: 47px;
      font-size: $font20;
      font-weight: bold;
      color: $fontColorFFF;
    }
  }
  .ant-modal-content{
    border-radius: 4px !important;
    overflow: hidden;
    .ant-modal-body{
      padding: 0 !important;
    }
    input{
      -webkit-appearance: none;
      font-size: $font24;
      font-weight: 400;
      height: 63px;
      font-weight: bold;
      color: $fontColor333;
      &::-webkit-input-placeholder{
        color: $fontColor9D9;
        line-height: 31px;
        font-weight: 400;
      }
      &::-moz-placeholder{ 
        color: $fontColor9D9;
        line-height: 31px;
        font-weight: 400;
      }
      &:-ms-input-placeholder {
        color: $fontColor9D9;
        line-height: 31px;
        font-weight: 400;
      }
    }
  }
}
.modal-content{
  width: 600px;
  @include flex(column, flex-start, flex-start);
  .close-btn{
    width: 100%;
    @include flex(row, flex-end, center);
    height: 64px;
    background: $bgColorF0F;
    img{
      cursor: pointer;
      margin-right: 32px;
      width: 32px;
      height: 32px;
    }
  }
  p{
    margin: 32px;
    margin-bottom: 0;
    font-size: $font20;
    color: $fontColor333;
    line-height: 26px;
  }
  .countdown-tip{
    position: absolute;
    z-index: 2;
    right: 32px;
    top: 32px;
    width: 120px;
    height: 63px;
    text-align: center;
    background: $bgColorE1E;
    border: 1px solid #D3D3D3;
    font-size: $font24;
    color: $fontColor447;
    line-height: 63px;
  }
  .footer-box{
    @include flex(row, flex-end, center);
    width: 100%;
    button{
      padding: 0;
      width: 160px;
      height: 79px;
      font-size: $font24;
      text-align: center;
      border-radius: 4px;
      &:first-child{
        margin-right: 32px;
        color: $fontColor447;
        border: 1px solid #447EDA;
      }
      &:last-child{
        color: $fontColorFFF;
        background: $bgColor447;
      }
    }
  }
}
