@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.home-container{
  background: $bgColorEBE;
  p, span{
    margin: 0;
    padding: 0;
  }
  display: flex;
  flex-direction: column;
  // margin-top: 122px;
  overflow-x: hidden;
  .share-box{
    margin-top: -14px;
    @include flex(row, center, center);
    .share-item{
      @include flex(row, center, center);
      width: 28px;
      height: 28px;
      background: $bgColor2C7;
      border-radius: 5px;
      margin-right: 9px;
      &:last-child{
        margin-left: 0px;
      }
      img{
        // margin-top: 6px;
        width: 16px;
        height: 16px;
      }
    }
  }
  .main{
    display: flex;
    flex-direction: column;
    .insurance-category{
      margin-top: -55px;
      @include flex(row, center, center);
      z-index: 10;
      height: 252px;
      .category-item{
        cursor: pointer;
        width: 206px;
        height: 100%;
        padding: 0 5px;
        box-sizing: border-box;
        @include flex(column, flex-start, center);
        color: $fontColorFFF;
        &:first-child{
          border-radius: 8px 0px 0px 8px;
        }
        &:last-child{
          border-radius: 0px 8px 8px 0px;
        }
        &:hover{
          color: $fontColor447;
          box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.32);
        }
        .insurance-title{
          margin-top: 22px;
          height: 24px;
          // font-size: $font24;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
          line-height: 24px;
        }
        img{
          margin-top: 30px;
        }
        .insurance-desc{
          margin-top: 22px;
          @include flex(column, flex-start, center);
          p{
            @include ellipsis();
            line-height: 30px;
            text-align: center;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 500;
            border-bottom: 1px solid transparent;
            &:hover {
              border-bottom-color: $bgColor447;
            }
          }
        }
      }
    }
    .product-promotion{
      // margin-top: 60px;
      @include flex(column, flex-start, center);
      padding: 80px 0px;
      box-sizing: border-box;
      background-color: $bgColorF0F;
      display: none;
      .recommend-product{
        margin-top: 54px;
        @include flex(row, center, center);
        .recommend-product-item{
          @include flex(column, flex-start, center);
          width: 348px;
          padding: 24px;
          box-sizing: border-box;
          border-radius: 10px;
          border: 1px solid #D4D4E1;
          margin-right: 32px;
          &:last-child{
            margin-right: 0px;
          }
          .img-box{
            text-align: center;
            width: 96px;
            height: 96px;
            border-radius: 50%;
            background: linear-gradient(90deg, #2876E2 0%, #3F8EFC 100%);
            img{
              margin-top: 24px;
              width: 48px;
              height: 48px;
            }
          }
          .product-name{
            text-align: center;
            margin-top: 19px;
            height: 33px;
            font-size: $font24;
            font-family: $fontFamilyPingFangSemibold;
            font-weight: 600;
            color: $fontColor303;
            line-height: 33px;
          }
          .product-desc{
            width: 300px;
            text-align: center;
            margin-top: 14px;
            font-size: $font16;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor636;
            line-height: 25px;
            @include ellipsis(4);
          }
        }
      }
    }
    .teams-box{
      @include flex(column, flex-start, center);
      padding: 80px 85px;
      box-sizing: border-box;
      background-color: $bgColorF0F;
      .teams-list{
        margin-top: 54px;
        @include flex(row, center, center);
        .teams-item{
          @include flex(column, flex-start, center);
          background: #FFFFFF;
          border-radius: 10px;
          overflow: hidden;
          margin-right: 30px;
          &:last-child{
            margin-right: 0px;
          }
          .member-cover{
            width: 255px;
            height: 264px;
          }
          .member-name{
            margin-top: 10px;
            text-align: center;
            height: 33px;
            font-size: $font24;
            font-family: $fontFamilyPingFangSemibold;
            font-weight: 600;
            color: $fontColor303;
            line-height: 33px;
            letter-spacing: 2px;
          }
          .member-position{
            margin-bottom: 16px;
            text-align: center;
            height: 25px;
            font-size: $font16;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor636;
            line-height: 25px;
          }
        }
      }
    }
    .hot-activity{
      @include flex(column, flex-start, center);
      margin-top: 79px;
      box-sizing: border-box;
      position: relative;
      .swiper-box{
        margin-top: 60px;
        width: 1248px !important;
        height: 304px;
        @include flex(row, flex-start, flex-start);
        .swiper-item{
          display: flex !important;
          flex-direction: row !important;
          align-items: flex-start !important;
          justify-content: center !important ;
          width: 1248px !important;
          height: 304px;
          .swiper-item-content{
            position: relative;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 288px;
            height: 100%;
            margin-right: 32px;
            &:hover{
              .title-box{
                box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.32);
              }
            }
            &:last-child{
              margin-right: 0px;
            }
            .content-cover{
              width: 288px;
              height: 150px;
              aspect-ratio: auto 288/150
            }
            .title-box{
              margin-top: -30px;
              z-index: 100;
              height: 160px;
              padding: 30px 32px 34px;
              box-sizing: border-box;
              background: $bgColorFFF;
              border-radius: 4px;
              .content-title{
                width: 193px;
                @include ellipsis(3);
                line-height: 36px;
                font-family: $fontFamilySourceHanSansCNBold;
                font-weight: bold;
                color: $fontColor333;
              }
            }
            .bottom-box{
              @include flex(column, center, center);
              z-index: 101;
              position: absolute;
              right: 0;
              bottom: 0;
              width: 48px;
              height: 48px;
              background: #447EDA;
              border-radius: 24px;
            }
          }
        }
      }
      .page-dot{
          margin-top: 61px;
          @include flex(row, center, center);
          pointer-events: none;
          .dot-item{
            pointer-events: auto;
            cursor: pointer;
            margin-right: 8px;
            &:last-child{
                margin-right: 0px;
            }
        }
      }
      .page-control{
        position: absolute;
        top: 228px;
        width: 1356px;
        height: 46px;
        @include flex(row, space-between, center);
        pointer-events: none;
        img{
            pointer-events: auto;
            cursor: pointer;
            width: 22px;
            height: 40px;
        }
      }
      .loading-box{
        @include flex(column, center, center);
        height: 35vh;
      }
      .pages-box{
        z-index: 10;
        position: absolute;
        top: 128px;
        right: 86px;
        width: 125px;
        background: $bgColorF0F;
        border-radius: 18px;
        @include flex(row, flex-start, center);
        padding: 6px 12px;
        box-sizing: border-box;
        img{
          cursor: pointer;
          width: 18px;
          height: 18px;
        }
        span{
          display: inline-block;
          flex: 1;
          text-align: center;
          height: 25px;
          font-size: $font16;
          font-family:$fontFamilyPingFangRegular;
          font-weight: 400;
          color: $fontColor636;
          line-height: 25px;
        }
      }
    }
    .hot-product{
      padding: 60px 0;
      box-sizing: border-box;
      @include flex(column, flex-start, center);
      margin-top: 58px;
      background-color: $bgColorFFF;
      // display: none;
      .product-list{
        @include flex(row, center, flex-start);
        margin-top: 60px;
      }
    }
    .insurance-comparison{
      margin-top: 60px;
      position: relative;
      display: none;
      .image-box{
        text-align: center;
        img {
          width: 100%;
          height: 460px;
          object-fit: cover;
        }
      }
      .comparison-box{
        @include flex(column, flex-start, flex-start);
        position: absolute;
        z-index: 11;
        width: 1248px;
        height: 460px;
        top: 0;
        left: calc(50vw - 624px);
        .comparison-title{
          margin-top: 135px;
          height: 49px;
          font-size: $font50;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
          color: $fontColorFFF;
          line-height: 49px;
        }
        .comparison-desc{
          margin-top: 24px;
          height: 20px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColorFFF;
          line-height: 20px;
        }
        .comparison-btn{
          cursor: pointer;
          text-align: center;
          margin-top: 41px;
          width: 210px;
          height: 54px;
          background: $bgColor447;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColorFFF;
          line-height: 54px;
        }
        .policy-escrow-title{
          margin-top: 135px;
          height: 49px;
          font-size: $font50;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
          color: $fontColorFFF;
          line-height: 49px;
        }
        .upload-btn{
          margin-top: 85px;
          cursor: pointer;
          text-align: center;
          margin-top: 41px;
          width: 210px;
          height: 54px;
          background: $bgColor447;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColorFFF;
          line-height: 54px;
        }
      }
    }
    .ambassador-box{
      padding: 79px 0 80px;
      box-sizing: border-box;
      @include flex(column, flex-start, center);
      background-color: $bgColorF7F;
      .ambassador-list{
        @include flex(row, space-between, flex-start);
        margin-top: 54px;
        width: 1414px;
        background-color: $bgColorFFF;
        border-radius: 8px;
        padding: 0 85px;
        box-sizing: border-box;
        .ambassador-item{
          @include flex(column, flex-start, flex-start);
          width: 288px;
          margin-right: 32px;
          padding: 60px 0 30px;
          box-sizing: border-box;
          &:last-child{
            margin-right: 0px;
          }
          img{
            margin-left: 50%;
            transform: translateX(-50%);
            width: 220px;
            height: 220px;
          }
          .name{
            width: 100%;
            text-align: center;
            margin: 33px 2px 0;
            @include ellipsis();
            height: 32px;
            font-size: $font26;
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
            color: $fontColor333;
            line-height: 32px;
          }
          .intro{
            // @include ellipsis(3);
            margin-top: 28px;
            // height: 90px;
            font-size: $font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
            line-height: 30px;
          }
        }
      }
    }
    .partner-company{
      // margin-top: 59px;
      background: $bgColorFFF;
      padding: 59px 0 30px;
      box-sizing: border-box;
      @include flex(column, flex-start, center);
      .company-list{
        margin-top: 60px;
        width: 1248px;
        margin-left: auto;
        margin-right: auto;
        @include flex(row, center, flex-start);
        flex-wrap: wrap;
        .company-item{
          margin-right: 62px;
          margin-bottom: 50px;
          width: 200px;
          height: 86px;
          background-color: $bgColorFFF;
          &:nth-child(5n+5){
            margin-right: 0;
          }
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .customer-praise{
      @include flex(column, flex-start, center);
      padding: 80px 85px;
      box-sizing: border-box;
      background-color: $bgColorF0F;
      .customer-list{
        margin-top: 54px;
        @include flex(row, center, center);
        .customer-item{
          margin-right: 32px;
          width: 348px;
          padding: 32px 20px 29px;
          box-sizing: border-box;
          border-radius: 10px;
          border: 1px solid #D4D4E1;
          &:last-child{
            margin-right: 0;
          }
          .customer-info{
            @include flex(row, space-around, space-around);
            img{
              width: 75px;
              height: 75px;
              margin-right: 20px;
            }
            .customer-intro{
              flex: 1;
              line-height: 25px;
              .customer-name{
                margin-top: 4px;
                @include ellipsis();
                height: 25px;
                font-size: $font18;
                font-family: $fontFamilyPingFangSemibold;
                font-weight: 600;
                color: $fontColor303;
              }
              .customer-position{
                margin-top: 4px;
                @include ellipsis();
                height: 25px;
                font-size: $font16;
                font-family: $fontFamilyPingFangRegular;
                font-weight: 400;
                color: $fontColor636;
              }
            }
          }
          .customer-evaluation{
            margin-top: 24px;
            font-size: $font16;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor636;
            line-height: 25px;
          }
        }
      }
    }
    .latest-news{
      margin-top: 80px;
      @include flex(column, flex-start, center);
      box-sizing: border-box;
      width: 1500px;
      margin-left: 50%;
      transform: translateX(-50%);
      background-color: $bgColorFFF;
      // @include  backgroundImg(1530px, 748px, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_latest_news_bg.png', cover);
      .news-list{
        margin-top: 60px;
        @include flex(row, flex-start, flex-start);
        .news-item{
          @include flex(column, flex-start, center);
          cursor: pointer;
          width: 288px;
          height: 474px;
          background: $bgColorF7F;
          border-radius: 8px;
          margin-right: 32px;
          overflow: hidden;
          &:hover{
            box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.32);
          }
          &:last-child{
            margin-right: 0px;
          }
          .news-cover{
            width: 288px;
            height: 150px;
          }
          .news-title{
            margin-top: 38px;
            width: 240px;
            @include ellipsis(2);
            height: 72px;
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
            color: $fontColor333;
            line-height: 36px;
          }
          .news-detail{
            // display: inline-block;
            width: 229px;
            height: 90px;
            line-height: 30px;
            @include ellipsis(3);
            margin-top: 40px;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
          }
          .more-box{
            width: 100%;
            margin-top: 36px;
            width: 288px;
            height: 48px;
            line-height: 48px;
            background: $bgColor447;
            border-radius: 0px 0px 8px 8px;
            text-align: center;
            // font-size: $font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColorFFF;
          }
        }
      }
    }
    .apply-customized-box{
      cursor: pointer;
      margin-top: 50px;
      width: 206px;
      height: 50px;
      background: linear-gradient(90deg, #2876E2 0%, #3F8EFC 100%);
      border-radius: 6px;
      @include flex(row, center, center);
      span{
        display: inline-block;
        height: 22px;
        font-size: $font16;
        font-family: $fontFamilyPingFangRegular;
        font-weight: 400;
        color: $fontColorFFF;
        line-height: 22px;
      }
      img{
        margin-left: 8px;
        width: 14px;
        height: 11px;
      }
    }
    .title{
      text-align: center;
      height: 22px;
      font-size: $font16;
      font-family: $fontFamilyPingFangRegular;
      font-weight: 600;
      color: $fontColorFD7;
      line-height: 22px;
    }
    .subtitle{
      text-align: center;
      height: 38px;
      font-size: $font40;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
      line-height: 38px;
    }
    .understand-more{
      width: 100%;
      cursor: pointer;
      margin-top: 60px;
      @include flex(row, center, center);
      line-height: 19px;
      // font-size: $font20;
      font-family: $fontFamilySourceHanSansCNRegular;
      font-weight: 400;
      color: $fontColor447;
      img{
        margin-left: 8px;
      }
    }
    .hot-activity-title{
      width: 100%;
      @include flex(row, center, center);
      line-height: 39px;
      // font-size: 40px;
      font-weight: bold;
      color: $fontColor333;
      :first-child{
        font-family: $fontFamilySourceHanSansCNBold;
      }
      :last-child{
        margin-left: 16px;
        font-family: $fontFamilySourceHanSansCNRegular;
      }
    }
    .common-title{
      width: 100%;
      @include flex(column, center, center);
      line-height: 50px;
      // font-size: $font40;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      color: $fontColor447;
      .common-box{
        @include flex(column, center, center);
        img{
          width: 100%;
          margin-top: 8px
        }
      }
    }
    .bottom-box{
      @include flex(row, flex-start, center);
      margin-top: 33px;
      span{
        height: 16px;
        line-height: 16px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor447;
      }
      img{
        margin-left: 7px;
        // width: 15px;
        // height: 9px;
      }
    }
  }
}
