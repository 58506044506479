@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.success-container{
  display: flex;
  flex-direction: column;
  .top-header{
    @include flex(row, center, center);
    background: $bgColorFDF;
    padding: 24px 0 56px;
    box-sizing: border-box;
    .header-list{
      @include flex(row, center, center);
      &:first-child{
        .header-item::before{
          content: '即時報價';
        }
      }
      &:last-child{
        .progress-line{
          display: none;
        }
        .header-item::before{
          content: '確認';
        }
      }
      .header-item{
        @include flex(column, center, center);
        position: relative;
        &::before{
          content: '投保詳情';
          position: absolute;
          width: 100px;
          top: 40px;
          // font-size: $font18;
          color: $fontColor333;
          line-height: 24px;
          text-align: center;
        }
        .receive-status{
          @include flex(column, center, center);
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background: $bgColor1E4;
          &.receive-status-n{
            background: $bgColor9D9;
          }
          img{
            width: 20px;
            height: 20px;
          }
          span{
            height: 24px;
            font-size: $font18;
            color: $fontColor333;
            line-height: 24px;
            font-weight: bold;
            color: $fontColorFFF;
          }
        }
      }
      .progress-line{
        width: 404px;
        height: 2px;
        background: $bgColor1E4;
      }
    }
  }
  .main{
    @include flex(column, center, center);
    width: 1272px;
    margin: 0 auto 88px;
    padding: 64px 88px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    img{
      width: 120px;
      height: 120px;
    }
    .title{
      margin-top: 40px;
      font-size: $font24;
      font-weight: bold;
      color: $fontColor333;
      line-height: 31px;
    }
    .sub-title{
      margin-top: 16px;
      font-size: $font18;
      color: $fontColor6F6;
      line-height: 24px;
    }
    button{
      margin: 32px 0 64px;
      padding: 0 46px;
      height: 58px;
      border: 1px solid #447EDA;
      font-size: $font20;
      text-align: center;
      border-radius: 4px;
      color: $fontColor447;
      background: transparent;
    }
  }
}