@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.contact-container{
  display: flex;
  flex-direction: column;
  background-color: $bgColorEBE;
  position: relative;
  .banner-title{
    display: inline-block;
    position: absolute;
    width: 1248px;
    top: 100px;
    left: calc(50vw - 624px);
    line-height: 49px;
    font-size: $font50;
    font-family: $fontFamilySourceHanSansCNBold;
    font-weight: bold;
    color: $fontColorFFF;
  }
  .banner{
    width: 100%;
    height: 320px;
    // object-fit: cover;
  }
  .main{
    @include flex(column, flex-start, center);
    .desc{
      margin-top: 58px;
      height: 80px;
      line-height: 40px;
      font-size: $font36;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor333;
    }
    .contact-way{
      width: 100%;
      padding: 88px 0 79px;
      @include flex(row, center, center);
      background-color: $bgColorFFF;
      .mail-box{
        @include flex(column, center, center);
        margin-right: 319px;
        &:last-child{
          margin-right: 0;
        }
        p{
          margin-top: 37px;
          height: 39px;
          line-height: 39px;
          font-size: $font40;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
          color: $fontColor333;
        }
        a{
          &:hover{
            text-decoration: underline;
          }
          text-decoration: none;
          margin-top: 24px;
          font-size: $font26;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor447;
          line-height: 30px;
        }
      }
    }
    .contact-now{
      position: relative;
      width: 1247px;
      margin: 165px auto;
      &:hover{
        .form-container{
          box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.64);
        }
      }
      .bg-box{
        width: 1247px;
        height: 514px;
        @include backgroundImg(100%, 514px, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_contact_us_form_bg.png', cover);
        .content{
          @include flex(column, center, flex-start);
          height: 100%;
          margin-left: 79px;
          .title{
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
            color: $fontColorFFF;
            height: 56px;
            line-height: 56px;
            font-size: $font58;
          }
          .sub-title{
            margin-top: 12px;
            line-height: 49px;
            font-size: $font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColorFFF;
            white-space: pre;
          }
        }
      }
      .protocol-box{
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
        line-height: 16px;
        a{
          color: $fontColor447;
        }
      }
      .form-container{
        position: absolute;
        // top: 50%;
        // transform: translateY(-50%);
        top: -74px;
        right: 80px;
        width: 400px;
        height: 662px;
        background: $bgColorFFF;
        border-radius: 8px;
        padding: 0 32px;
        .tabs-box{
          margin-top: 31px;
          @include flex(row, space-between, center);
          .tabs-item{
            cursor: pointer;
            @include flex(column, center, center);
            // width: 120px;
            height: 45px;
            text-align: center;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
            border-bottom: 4px solid transparent;
            &.tabs-item-active{
              font-family: $fontFamilySourceHanSansCNBold;
              font-weight: bold;
              color: $fontColor447;
              border-bottom-color: $bgColor447;
            }
            span{
              height: 26px;
              line-height: 26px;
              font-size: $font26;
            }
          }
        }
        // .input-label{
        //   margin-top: 24px;
        //   @include flex(row, flex-start, center);
        //   margin-bottom: 4px;
        //   .label-box{
        //     @include flex(row, flex-start, center);
        //     height: 16px;
        //     line-height: 16px;
        //     font-size: $font16;
        //     font-family: $fontFamilySourceHanSansCNMedium;
        //     font-weight: 500;
        //     color: $fontColor333;
        //     :last-child{
        //       color: $fontColorFF0;
        //     }
        //   }
        // }
        textarea{
          resize: none; 
        }
        input, textarea{
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor333;
          &::-webkit-input-placeholder{
            color: $fontColor9D9;
          }
          &::-moz-placeholder{   /* Mozilla Firefox 19+ */
            color: $fontColor9D9;
          }
          &:-ms-input-placeholder {
            color: $fontColor9D9;
          }
        }
        input[type=number] {  
          -moz-appearance:textfield;  
        }  
        input[type=number]::-webkit-inner-spin-button,  
        input[type=number]::-webkit-outer-spin-button {  
            -webkit-appearance: none;  
            margin: 0;  
        } 
        .submit-btn{
          @include flex(row, center, center);
          // margin-top: 24px;
          button{
            padding: 0;
            width: 100%;
            height: 46px;
            background-color: $bgColor447;
            text-align: center;
            font-size: $font18;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 500;
            color: $fontColorFFF;
            line-height: 46px;
            border-color: transparent;
          }
        }
      }
    }
  }
}