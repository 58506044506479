@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.program-made{
  position: relative;
  width: 100%;
  .bg-img{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 461px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    object-fit: cover;
  }
  .bg-mask{
    position: absolute;
    // z-index: -1;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    background: $bgColorF7F;
  }
  .made-box{
    position: relative;
    z-index: 10;
    @include flex(row, flex-end, flex-start);
    width: 1248px;
    margin: 0 auto;
    .form-container{
      @include flex(column, flex-start, center);
      margin-right: 13px;
      width: 744px;
      // height: 672px;
      background: $bgColorFFF;
      box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.32);
      border-radius: 8px;
      padding: 32px;
      padding-bottom: 10px;
      box-sizing: border-box;
      position: relative;
      .success-content{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @include flex(column, center, center);
        background: $bgColorFFF;
        img{
          width: 120px;
          height: 120px;
          margin-bottom: 40px;
        }
        .title{
          height: 24px;
          font-size: $font24;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: bold;
          color: $fontColor333;
          margin-bottom: 16px;
        }
        .desc{
          height: 20px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          margin-bottom: 40px;
        }
        button{
          padding: 0;
          width: 252px;
          height: 58px;
          border: 1px solid #447EDA;
          border-radius: 4px;
          line-height: 58px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor447;
        }
      }

      .form-content{
        position: relative;
        .tabs-box{
          @include flex(row, space-evenly, center);
          .tabs-item{
            cursor: pointer;
            @include flex(column, center, center);
            // width: 120px;
            height: 45px;
            text-align: center;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
            border-bottom: 4px solid transparent;
            &.tabs-item-active{
              font-family: $fontFamilySourceHanSansCNBold;
              font-weight: bold;
              color: $fontColor447;
              border-bottom-color: $bgColor447;
            }
            span{
              height: 26px;
              line-height: 26px;
              font-size: $font26;
            }
          }
        }
        .mobile-code{
          width: 100%;
          border: 1px solid #d9d9d9;
          height: 40px;
          span{
            height: 40px;
            line-height: 40px;
            margin-left: 16px;
            font-size: $font18;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
          }
        }
        textarea{
          resize: none;
        }
        input, textarea{
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor333;
          &::-webkit-input-placeholder{
            color: $fontColor9D9;
          }
          &::-moz-placeholder{   /* Mozilla Firefox 19+ */
            color: $fontColor9D9;
          }
          &:-ms-input-placeholder {
            color: $fontColor9D9;
          }
        }
        input[type=number] {
          -moz-appearance:textfield;
        }
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        .upload-box {
          @include flex(column, center, center);
          width: 100%;
          padding: 14px;
          height: 124px;
          background: $bgColorF5F;
          border: 2px solid $bgColorD3D;
          border-radius: 4px;
          border-style: dashed;
          font-size: 18px;
          div {
            @include flex(row, center, center);
            .bg-img {
              position: relative;
              height: 40px;
              width: 40px;
              margin-bottom: 14px;
            }
            span:first-child {
              color: $fontColor447;
            }
            span:last-child {
              color: $fontColor9D9;
            }
          }
        }
        .file-list{
          @include flex(row, flex-start, flex-start);
          padding: 20px;
          padding-bottom: 0;
          // height: 222px;
          // background: $bgColorF5F;
          border: 2px solid $bgColorD3D;
          border-radius: 4px;
          border-style: dashed;
          font-size: 18px;
          flex-wrap: wrap;
          .file-item{
            position: relative;
            @include flex(column, center, center);
            width: 110px;
            height: 80px;
            margin-bottom: 20px;
            margin-right: 20px;
            &:nth-child(5n){
              margin-right: 0;
            }
            // background: aquamarine;
            .file-icon{
              width: 28px;
              height: 24px;
              margin-bottom: 10px;
            }
            .upload-file-name{
              @include ellipsis;
              font-size: 16px;
              color: $fontColor333;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
            }
            .file-close{
              position: absolute;
              cursor: pointer;
              top: -10px;
              right: -10px;
              width: 30px;
              height: 30px;
            }
            .file-add{
              width: 36px;
              height: 36px;
              cursor: pointer;
            }
          }
        }
        .protocol-box{
          margin-top: 24px;
          font-size: $font14;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor333;
          line-height: 16px;
          a{
            color: $fontColor447;
          }
        }
        .submit-btn{
          @include flex(row, center, center);
          margin-top: 24px;
          bottom: 0;
          button{
            width: 120px;
            padding: 0;
            height: 46px;
            background-color: $bgColor447;
            text-align: center;
            font-size: $font18;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 500;
            color: $fontColorFFF;
            line-height: 46px;
            border-color: transparent;
          }
        }
      }
    }
    :global{
      .ant-upload{
        cursor: pointer;
        width: 100%;
        height: 100%;
      }
    }
    input{
      font-size: 16px;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      &::-webkit-input-placeholder{
        // height: 16px;
        // line-height: 16px;
        color: $fontColor9D9;

      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        // height: 16px;
        color: $fontColor9D9;
        // line-height: 16px;
      }
    }
    input[type=number] {
      -moz-appearance:textfield;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
  }
}