@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.insured-container{
  display: flex;
  flex-direction: column;
  .top-header{
    @include flex(row, center, center);
    background: $bgColorFDF;
    padding: 24px 0 56px;
    box-sizing: border-box;
    .header-item{
      @include flex(column, center, center);
      position: relative;
      &::before{
        content: '投保詳情';
        position: absolute;
        width: 100px;
        top: 40px;
        height: 24px;
        font-size: $font18;
        color: $fontColor333;
        line-height: 24px;
        text-align: center;
      }
      &:first-child{
        &::before{
          content: '即時報價';
        }
      }
      &.header-item-preview{
        &::before{
          content: '預覽及付款';
        }
      }
      &:last-child{
        &::before{
          content: '確認';
        }
      }
      .receive-status{
        @include flex(column, center, center);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $bgColor1E4;
        &.receive-status-n{
          background: $bgColor9D9;
        }
        img{
          width: 20px;
          height: 20px;
        }
        span{
          height: 24px;
          font-size: $font18;
          color: $fontColor333;
          line-height: 24px;
          font-weight: bold;
          color: $fontColorFFF;
        }
      }
    }
    .progress-line{
      width: 291px;
      height: 2px;
      background: $bgColor1E4;
    }
  }
  .loading-box{
    @include flex(column, center, center);
    position: fixed;
    z-index: 10;
    top: 112px;
    width: 100%;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
  }
  .form-container{
    width: 1272px;
    margin: 0 auto;
    padding: 56px 88px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    .form-demand{
      height: 31px;
      font-size: $font24;
      font-family: $fontFamilyMicrosoftYaHei;
      color: $fontColor333;
      line-height: 31px;
    }
    .title-box{
      margin: 56px 0 24px;
      width: 100%;
      @include flex(row, flex-start, center);
      span{
        height: 52px;
        font-size: $font40;
        font-weight: bold;
        color: $fontColor1E4;
        line-height: 52px;
      }
      div{
        flex: 1;
        margin-left: 32px;
        height: 2px;
        background: $bgColor1E4;
      }
    }
    .address-box{
      @include flex(column, flex-start, flex-start);
      margin-bottom: 16px;
      .title-box{
        margin-bottom: 0;
      }
      .address-desc{
        margin-top: 8px;
        height: 26px;
        font-size: $font20;
        font-family: $fontFamilyMicrosoftYaHeiBold;
        font-weight: bold;
        color: $fontColor1E4;
        line-height: 26px;
      }
    }
    .label-box{
      width: 100%;
      @include flex(row, flex-start, center);
      margin-top: 32px;
      margin-bottom: 16px;
      .label-desc{
        position: relative;
        margin-right: 6%;
        width: 47%;
        @include flex(row, flex-start, center);
        &:last-child{
          margin-right: 0;
        }
        span{
          height: 26px;
          font-size: $font20;
          color: $fontColor333;
          line-height: 26px;
          &::after{
            content: '*';
            color: $fontColorFF0;
          }
          &.label-normal{
            &::after{
              display: none;
            }
          }
        }
        img{
          cursor: pointer;
          margin-left: 8px;
          width: 24px;
          height: 24px;
        }
        p{
          height: 40px;
          font-size: $font30;
          font-weight: bold;
          color: $fontColor333;
          line-height: 40px;
          text-align: center;
        }
        .symbol-label{
          position: absolute;
          top: 51px;
          right: 0;
          width: 30px;
        }
        .input-tips{
          position: absolute;
          top: 105px;
          left: 0;
          text-align: center;
          width: 232px;
          height: 0px;
          overflow: hidden;
          background: $bgColorE1E;
          transition: all 0.3s;
          &.input-tips-active{
            transition: all 0.3s;
            height: 58px;
          }
          span{
            &::after{
              display: none;
            }
            height: 58px;
            font-size: $font20;
            color: $fontColor447;
            line-height: 58px;
          }
        }
      }
    }
    .phone-prefix{
      height: 61px;
      line-height: 61px;
      font-size: $font24;
      font-weight: bold;
      color: $fontColor333;
    }
    .icon-input-search{
      width: 40px;
      height: 40px;
      @include backgroundImg(40px, 40px, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_insured_insurance_form_search.png', cover);
    }
    // .traveler-container{
    //   width: 100%;
      
      .traveler-list{
        // width: 100%;
        // @include flex(column, flex-start, flex-start);
        .traveler-item{
          background: #FAFAFA;
          border: 1px solid #1E4994;
          padding: 32px;
          box-sizing: border-box;
          margin-bottom: 32px;
          .delete-traveler{
            cursor: pointer;
            text-align: right;
            margin-top: 16px;
            margin-bottom: 24px;
            span{
              text-align: center;
              display: inline-block;
              width: 280px;
              height: 56px;
              background: #E1EAF9;
              border-radius: 4px;
              border: 1px solid #447EDA;
              img{
                margin-top: 8px;
                width: 40px;
                height: 40px;
              }
            }
            
          }
        }
      }
    // }
    .user-declaration{
      margin-top: 72px;
      @include flex(column, flex-start, flex-start);
      .declaration-item{
        margin-bottom: 24px;
        .top-box{
          cursor: pointer;
          @include flex(row, flex-start, flex-start);
          img{
            width: 28px;
            height: 28px;
          }
          .declaration-list{
            @include flex(column, flex-start, flex-start);
            .declaration-content{
              margin-left: 24px;
              p{
                margin-bottom: 8px;
              }
            }
          }
          p{
            margin-left: 8px;
            font-size: $font20;
            color: $fontColor333;
            font-weight: bold;
            line-height: 26px;
          }
          a{
            color: $fontColor1E4;
            text-decoration: underline;
          }
        }
        .err-msg{
          margin-left: 36px;
          height: 26px;
          font-size: $font20;
          color: $fontColorFF0;
          line-height: 26px;
        }
      }
    }
    .add-traveler{
      cursor: pointer;
      @include flex(row, center, center);
      border: 1px dotted #1E4994;
      padding: 32px 0 ;
      box-sizing: border-box;
      img{
        width: 20px;
        height: 20px;
        margin-right: 16px;
      }
      span{
        height: 31px;
        font-size: $font24;
        font-family: $fontFamilyMicrosoftYaHei;
        color: $fontColor447;
        line-height: 31px;
      }
    }
    :global{
      .ant-form{
        .ant-select-selector{
          height: 63px;
        }
        .ant-select-selection-item{
          line-height: 63px;
          font-size: $font24;
          font-weight: bold;
          color: $fontColor333;
        }
        .ant-select-arrow{
          width: 40px;
          height: 40px;
          right: 8px !important;
          top: 16.5px !important;
        }
        .ant-picker{
          width: 100%;
          height: 63px;
        }
        .ant-input-group-addon{
          padding: 0;
        }
        .ant-form-item-explain-error{
          height: 26px;
          font-size: $font20;
          color: $fontColorFF0;
          line-height: 26px;
          margin-bottom: 24px;
        }
        .ant-input[disabled] {
          background: #F0F0F0;
        }
        .ant-picker-disabled{
          background: #F0F0F0;
        }
        .ant-select-selection-placeholder{
          height: 63px;
          font-size: $font24;
          color: $fontColor9D9;
          line-height: 63px;
          font-weight: 400;
        }
        .ant-radio-group{
          .ant-radio-wrapper{
            margin-right: 64px;
            height: 32px;
            span:last-child:last-child{
              height: 32px;
              font-size: $font24;
              font-family: $fontFamilyMicrosoftYaHei;
              color: $fontColor333;
              line-height: 32px;
            }
            .ant-radio{
              top: 7px;
              .ant-radio-inner{
                width: 32px;
                height: 32px;
                position: static;
                top: 0;
                &::after{
                  width: 24px;
                  height: 24px;
                  top: 4px;
                  left: 4px;
                  border-radius: 50%;
                }
              }
            }
            
          }
        }
        .ant-input-affix-wrapper{
          padding: 0;
          padding-left: 4px;
        }
        input{
          -webkit-appearance: none;
          font-size: $font24;
          font-weight: 400;
          height: 63px;
          font-weight: bold;
          color: $fontColor333;
          &::-webkit-input-placeholder{
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
          &::-moz-placeholder{ 
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
          &:-ms-input-placeholder {
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .next-btn{
    @include flex(row, flex-end, center);
    width: 1272px;
    margin: 64px auto 88px;
    button{
      width: 320px;
      height: 79px;
      font-size: $font24;
      text-align: center;
      border-radius: 4px;
      color: $fontColorFFF;
      background: $bgColor447;
    }
  }
  input[type=number] {  
    -moz-appearance:textfield;  
  }  
  input[type=number]::-webkit-inner-spin-button,  
  input[type=number]::-webkit-outer-spin-button {  
    -webkit-appearance: none;  
    margin: 0;  
  } 
}
.destination-list{
  @include flex(column, flex-start, flex-start);
  position: absolute;
  z-index: 12;
  top: 115px;
  height: 240px;
  left: 0;
  right: 0;
  border: 1px solid #D3D3D3;
  background-color: $bgColorFFF;
  overflow-y: scroll;
  .destination-item{
    cursor: pointer;
    width: 100%;
    height: 60px;
    font-size: $font24;
    font-family: $fontFamilyMicrosoftYaHei;
    font-weight: normal;
    color: $fontColor333;
    line-height: 60px;
    background: $bgColorFFF;
    padding: 0 16px;
    box-sizing: border-box;
    span{
      &::after{
        display: none;
      }
      height: 60px;
      font-size: $font24 !important;
      font-family: $fontFamilyMicrosoftYaHeiBold;
      font-weight: bold;
      line-height: 60px;
    }
    &:hover{
      font-family: $fontFamilyMicrosoftYaHeiBold;
      font-weight: bold;
      background: $bgColorFAF;
    }
  }
}
