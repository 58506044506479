@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.container{
  display: flex;
  flex-direction: column;
  width: 1248px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  .main{
    display: flex;
    flex-direction: column;
    padding: 40px 76px;
    h1{
      font-size: 40px;
      text-align: center;
    }
    h3{
      font-size: 20px;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    ul li{
      // font-size: 14px;
      margin: 10px 0;
    }
  }
}