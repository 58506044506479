@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.compare-product{
  @include flex(column, flex-start, flex-start);
  .main{
    @include flex(column, flex-start, flex-start);
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    .search-nav{
      @include flex(row,  center, center);
      position: sticky;
      z-index: 1000;
      top: 122px;
      left: 0;
      width: 100%;
      height: 0px;
      transition: all 0.4s;
      background: $bgColorFFF;
      overflow: hidden;
      &.search-nav-animate{
        height: 78px;
        transition: height 0.4s;
      }
      .input-box{
        width: 772px;
        height: 46px;
        padding: 0 24px;
        box-sizing: border-box;
        border: 2px solid #D3D3D3;
        @include flex(row,  flex-start, center);
        img{
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        input{
          flex: 1;
          border-radius: 0;
          border: none;
          background: transparent !important;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          height: 46px;
          color: $fontColor333;
          &::-webkit-input-placeholder{
            color: $fontColor9D9;

          }
          &::-moz-placeholder{   /* Mozilla Firefox 19+ */
            color: $fontColor9D9;
          }
        }
      }
      span{
        cursor: pointer;
        margin-left: 8px;
        width: 120px;
        height: 46px;
        background: $bgColor1E4;
        text-align: center;
        line-height: 46px;
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColorFFF;
      }
    }
    .filter-title{
      margin-top: 8px;
      width: 100%;
      @include flex(row,  flex-start, center);
      p{
        margin-right: 6px;
        height: 16px;
        line-height: 16px;
        // font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
      }
      img{
        width: 7px;
        height: 11px;
      }
    }
    .filter-box{
      width: 100%;
      margin-top: 15px;
      @include flex(column,  flex-start, flex-start);
      border: 1px solid #D3D3D3;
      border-radius: 8px;
      padding: 0px 16px;
      box-sizing: border-box;
      background-color: $fontColorFFF;
      .select-insurance-company{
        width: 100%;
        @include flex(column,  flex-start, flex-start);
        border-bottom: 1px solid #D3D3D3;
        .company-box{
          @include flex(row,  flex-start, flex);
          line-height: 16px;
          // font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          padding-bottom: 4px;
          width: 100%;
          p{
            margin-top: 12px;
            width: 80px;
            height: 16px;
            margin-left: 7px;
            margin-right: 40px;
            color: $fontColor9D9;
          }
          .species-list{
            flex: 1;
            @include flex(row,  flex-start, center);
            flex-wrap: wrap;
            .species-menu-box{
              cursor: pointer;
              @include flex(row,  center, center);
              margin: 12px 40px 12px 0;
              &:last-child{
                margin-right: 0px;
              }
              img{
                width: 18px;
                height: 18px;
                margin-right: 4px;
              }
              .species-menu{
                user-select: none;
                cursor: pointer;
                height: 16px;
                color: $fontColor333;
                &.species-menu-active{
                  color: $fontColor447;
                }
              }
            }
          }
          .more-btn{
            cursor: pointer;
            margin-top: 12px;
            width: 44px;
            height: 20px;
            border: 1px solid #447EDA;
            line-height: 20px;
            text-align: center;
            // font-size: $font14;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColor447;
          }
        }
        .select-box{
          width: 100%;
          margin-top: 12px;
          margin-bottom: 16px;
          @include flex(row, center, center);
          line-height: 28px;
          // font-size: $font14;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColorFFF;
          span{
            cursor: pointer;
            text-align: center;
            background: $bgColor447;
            width: 44px;
            height: 28px;
          }
          :last-child{
            margin-left: 32px;
            color: $fontColor333;
            border: 1px solid #9D9D9D;
            background: $bgColorFFF;
          }
        }
      }
      .currency-box{
        @include flex(row,  flex-start, flex-start);
        line-height: 16px;
        // font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        width: 100%;
        padding-top: 16px;
        border-bottom: 1px solid #D3D3D3;
        &:last-child{
          border-bottom: none;
        }
        p{
          width: 80px;
          height: 16px;
          margin-left: 8px;
          margin-right: 36px;
          color: $fontColor9D9;
        }
        .currency-list{
          flex: 1;
          @include flex(row,  flex-start, center);
          flex-wrap: wrap;
          .currency-menu-box{
            margin-right: 40px;
            margin-bottom: 16px;
            &:last-child{
              margin-right: 0px;
            }
            .currency-menu{
              user-select: none;
              cursor: pointer;
              height: 16px;
              color: $fontColor333;
              &.currency-menu-active{
                color: $fontColor447;
              }
            }
          }
        }
      }
    }
    .sort-box{
      @include flex(row,  flex-start, center);
      width: 100%;
      margin-top: 16px;
      background: $bgColorFAF;
      height: 48px;
      border: 1px solid #D3D3D3;
      display: none;
      span{
        user-select: none;
        cursor: pointer;
        height: 48px;
        line-height: 48px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor9D9;
        padding: 0 24px;
        box-sizing: border-box;
        &.sort-active{
          color: $fontColorFFF;
          background-color: $bgColor447;
        }
      }
    }
    .category-box{
      margin: -44px -98px -60px -98px;
      width: 1096px;
      height: 500px;
      border-radius: 8px;
      background-color: $bgColorEBE;
      z-index: -10;
      color: $fontColor333;
      font-family: $fontFamilySourceHanSansCNRegular;
      @include flex(row, center, flex-start);
      .category-content {
        width: 900px;
        .filter-result {
          margin-top: 67px;
          span:nth-child(2) {
            color: $fontColor447;
          }
        }
        .category-title {
          margin-top: 75px;
          height: 35px;
          line-height: 35px;
          @include flex(row, center);
          img {
            height: 30px;
          }
          span {
            margin-left: 8px;
            color: $fontColor447;
            font-family: $fontFamilySourceHanSansCNBold;
          }
        }
        .category-item-title {
          @include flex(row, center);
          margin-top: 20px;
          line-height: 58px;
          font-family: $fontFamilySourceHanSansCNBold;
        }
        .category-item-sub-title {
          @include flex(row, center);
          height: 36px;
          line-height: 36px;
          font-family: $fontFamilySourceHanSansCNBold;
        }
        .category-item-info {
          @include flex(row, center);
          line-height: 36px;
          :first-child {
            width: 12px;
            height: 12px;
            background: $bgColor447;
          }
          :last-child {
            padding-left: 4px;
            @include ellipsis(1);
          }
        }
      }
    }
    .product-box{
      @include flex(column, flex-start, flex-start);
      width: 900px;
      margin-top: 8px;
      margin-bottom: 60px;
      .product-list{
        width: 100%;
        @include flex(column, flex-start, flex-start);
      }
    }
  }
}