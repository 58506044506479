/*
  样式规范表
*/
$min-width: 1280px;  //容器安全区域宽度
$font80:  80px;
$font72:  72px;
$font58:  58px;
$font50:  50px;
$font40:  40px;
$font36:  36px;
$font32:  32px;
$font30:  30px;
$font26:  26px;
$font24:  24px;
$font20:  20px;
$font18:  18px;
$font16:  16px;
$font14:  14px;
$font12:  12px;

// 字体颜色
$fontColor2C7:  #2C7AE7;
$fontColorFD7:  #FD7E14;
$fontColor303:  #303036;
$fontColor636:  #63636B;
$fontColorFFF:  #FFFFFF;
$fontColor333:  #333333;
$fontColor1E4:  #1E4994;
$fontColor447:  #447EDA;
$fontColor9D9:  #9D9D9D;
$fontColor6F6:  #6F6F6F;
$fontColorFF0:  #FF0000;
$fontColor0D0:  #0D0D0D;
$fontColorF2A:  #F2A915;
$fontColorF7F:  #F7F7F7;
$fontColor6E6:  #6E6E75;
$fontColor437:  #437EDA;
$fontColor0FF:  #0000FF;
$fontColorD3D:  #D3D3D3;

// 背景色
$bgColor303:  #303036;
$bgColorF0F:  #F0F4F8;
$bgColorFFF:  #FFFFFF;
$bgColor2C7:  #2C7AE7;
$bgColorFD7:  #FD7E14;
$bgColorB7B:  #B7B7BA;
$bgColor447:  #447EDA;
$bgColorF2A:  #F2A915;
$bgColorF5F:  #F5F5F5;
$bgColorC8C:  #C8C8C8;
$bgColorFAF:  #FAFAFA;
$bgColor3B5:  #3B559F;
$bgColorD3D:  #D3D3D3;
$bgColor1E4:  #1E4994;
$bgColorFFFA: #FFFAEC;
$bgColor000:  #000000;
$bgColorEBE:  #EBEEF5;
$bgColorF7F:  #F7F7F7;
$bgColorFCE:  #FCEE19;
$bgColor437:  #437EDA;
$bgColorFDF:  #FDF1D9;
$bgColorE1E:  #E1EAF9;
$bgColor9D9:  #9D9D9D;
$bgColorF6F:  #F6F9FD;
$bgColor8DB:  #8DB0E7;
$bgColorE3E:  #E3EEFD;

// 常规字体
$fontFamilyPingFangRegular: PingFang-Regular;
$fontFamilyPingFangSemibold: PingFang-Semibold;
$fontFamilySourceHanSansCNRegular: SourceHanSansCN-Regular;
$fontFamilySourceHanSansCNBold: SourceHanSansCN-Bold;
$fontFamilySourceHanSansCNMedium: SourceHanSansCN-Medium;
$fontFamilySourceHanSansCNHeavy: SourceHanSansCN-Heavy;
$fontFamilySourceHanSansCNLight: SourceHanSansCN-Light;
$fontFamilySourceHanSansCNExtraLight: SourceHanSansCN-ExtraLight;
$fontFamilyMicrosoftYaHei: MicrosoftYaHei;
$fontFamilyMicrosoftYaHeiBold: MicrosoftYaHei-Bold;