.ant-select-selection-item{
  font-size: 16px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  color: #333333;
}
.ant-select-arrow{
  top: 50% !important;
}
.ant-select{
  width: 100%  !important;
}

.ant-select-selection-placeholder{
  font-family: SourceHanSansCN-Regular !important;
  font-weight: 400 !important;
  color: #9D9D9D !important;
}