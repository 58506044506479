@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.receive-insurance{
  display: flex;
  flex-direction: column;
  .top-header{
    @include flex(row, center, center);
    background: $bgColorFDF;
    padding: 24px 0 56px;
    box-sizing: border-box;
    .header-item{
      @include flex(column, center, center);
      position: relative;
      &::before{
        content: '投保詳情';
        position: absolute;
        width: 72px;
        top: 40px;
        height: 24px;
        font-size: $font18;
        color: $fontColor333;
        line-height: 24px;
        text-align: center;
      }
      &:first-child{
        &::before{
          content: '即時報價';
        }
      }
      &:last-child{
        &::before{
          content: '確認';
        }
      }
      .receive-status{
        @include flex(column, center, center);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $bgColor1E4;
        &.receive-status-n{
          background: $bgColor9D9;
        }
        img{
          width: 20px;
          height: 20px;
        }
        span{
          height: 24px;
          font-size: $font18;
          color: $fontColor333;
          line-height: 24px;
          font-weight: bold;
          color: $fontColorFFF;
        }
      }
    }
    .progress-line{
      width: 404px;
      height: 2px;
      background: $bgColor1E4;
    }
  }
  .loading-box{
    @include flex(column, center, center);
    position: fixed;
    z-index: 10;
    top: 112px;
    width: 100%;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
  }
  .form-container{
    width: 1272px;
    margin: 0 auto;
    padding: 64px 88px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    .company-profile{
      @include flex(row, flex-start, flex-start);
      width: 100%;
      background: $bgColor447;
      padding: 20px 8px ;
      box-sizing: border-box;
      img{
        width: 400px;
        height: 244px;
      }
      .profile-box{
        // margin-left: 22px;
        flex: 1;
        @include flex(column, flex-start, flex-start);
        .title{
          // margin-top: 24px;
          height: 52px;
          font-size: $font40;
          font-weight: bold;
          color: $fontColorFFF;
          line-height: 52px;
        }
        .tag{
          margin-left: 22px;
          margin-top: 62px;
          height: 26px;
          font-size: $font20;
          color: $fontColorFFF;
          line-height: 26px;
        }
        .desc{
          margin-left: 22px;
          margin-top: 23px;
          height: 31px;
          font-size: $font24;
          font-weight: bold;
          color: $fontColorFFF;
          line-height: 31px;
        }
      }
    }
    .title-box{
      margin: 56px 0 24px;
      width: 100%;
      @include flex(row, flex-start, center);
      span{
        height: 52px;
        font-size: $font40;
        font-weight: bold;
        color: $fontColor1E4;
        line-height: 52px;
      }
      div{
        flex: 1;
        margin-left: 32px;
        height: 2px;
        background: $bgColor1E4;
      }
    }
    .label-box{
      width: 100%;
      @include flex(row, flex-start, center);
      margin-bottom: 16px;
      .label-desc{
        position: relative;
        margin-right: 6%;
        width: 47%;
        @include flex(row, flex-start, center);
        &:last-child{
          margin-right: 0;
        }
        span{
          height: 26px;
          font-size: $font20;
          color: $fontColor333;
          line-height: 26px;
          &::after{
            content: '*';
            color: $fontColorFF0;
          }
        }
        img{
          cursor: pointer;
          margin-left: 8px;
          width: 24px;
          height: 24px;
        }
        p{
          height: 40px;
          font-size: $font30;
          font-weight: bold;
          color: $fontColor333;
          line-height: 40px;
          text-align: center;
        }
        .symbol-label{
          position: absolute;
          top: 51px;
          right: 0;
          width: 30px;
        }
        .input-tips{
          position: absolute;
          top: 105px;
          left: 0;
          text-align: center;
          width: 232px;
          height: 0px;
          overflow: hidden;
          background: $bgColorE1E;
          transition: all 0.3s;
          &.input-tips-active{
            transition: all 0.3s;
            height: 58px;
          }
          span{
            &::after{
              display: none;
            }
            height: 58px;
            font-size: $font20;
            color: $fontColor447;
            line-height: 58px;
          }
        }
        .send-code-btn{
          cursor: pointer;
          position: absolute;
          z-index: 100;
          top: 50px;
          right: 8px;
          text-align: center;
          width: 177px;
          height: 47px;
          background: $bgColor447;
          border-radius: 4px;
          border: 1px solid #D3D3D3;
          line-height: 47px;
          font-size: $font20;
          font-weight: bold;
          color: $fontColorFFF;
        }
      }
    }
    .phone-prefix{
      height: 61px;
      line-height: 61px;
      font-size: $font24;
      font-weight: bold;
      color: $fontColor333;
    }
    .relation-box{
      height: 63px;
      background: #F0F0F0;
      border: 1px solid #D3D3D3;
      box-sizing: border-box;
      @include flex(row, space-between, center);
      span{
        margin-left: 16px;
        height: 31px;
        font-size: $font24;
        font-weight: bold;
        color: $fontColor333;
        line-height: 31px;
      }
      img{
        margin-right: 8px;
      }
    }
    .user-declaration{
      margin-top: 72px;
      @include flex(column, flex-start, flex-start);
      .declaration-item{
        margin-bottom: 24px;
        .top-box{
          cursor: pointer;
          @include flex(row, flex-start, flex-start);
          img{
            width: 28px;
            height: 28px;
          }
          .declaration-info{
            // background: aqua;
            margin-left: 8px;
            // height: 26px;
            font-size: $font20;
            color: $fontColor333;
            font-weight: bold;
            line-height: 26px;
            a{
              color: $fontColor1E4;
              text-decoration: underline;
            }
          }
        }
        .err-msg{
          margin-left: 36px;
          height: 26px;
          font-size: $font20;
          color: $fontColorFF0;
          line-height: 26px;
        }
      }
    }
    :global{
      .ant-form{
        .ant-select-selector{
          height: 63px;
        }
        .ant-select-selection-item{
          line-height: 63px;
          font-size: $font24;
          font-weight: bold;
          color: $fontColor333;
        }
        .ant-select-arrow{
          width: 40px;
          height: 40px;
          right: 8px !important;
          top: 16.5px !important;
        }
        .ant-picker{
          width: 100%;
          height: 63px;
        }
        .ant-input-group-addon{
          padding: 0;
        }
        .ant-form-item-explain-error{
          height: 26px;
          font-size: $font20;
          color: $fontColorFF0;
          line-height: 26px;
          margin-bottom: 24px;
        }
        .ant-input[disabled] {
          background: #F0F0F0;
        }
        .ant-picker-disabled{
          background: #F0F0F0;
        }
        .ant-select-selection-placeholder{
          height: 63px;
          font-size: $font24;
          color: $fontColor9D9;
          line-height: 63px;
          font-weight: 400;
        }
        input{
          -webkit-appearance: none;
          font-size: $font24;
          font-weight: 400;
          height: 63px;
          font-weight: bold;
          color: $fontColor333;
          &::-webkit-input-placeholder{
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
          &::-moz-placeholder{ 
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
          &:-ms-input-placeholder {
            color: $fontColor9D9;
            line-height: 31px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .next-btn{
    @include flex(row, flex-end, center);
    width: 1272px;
    margin: 64px auto 88px;
    button{
      width: 320px;
      height: 79px;
      font-size: $font24;
      text-align: center;
      border-radius: 4px;
      color: $fontColorFFF;
      background: $bgColor447;
    }
  }
  input[type=number] {  
    -moz-appearance:textfield;  
  }  
  input[type=number]::-webkit-inner-spin-button,  
  input[type=number]::-webkit-outer-spin-button {  
    -webkit-appearance: none;  
    margin: 0;  
  } 
}

:global{
  .ant-modal-content{
    border-radius: 4px !important;
    overflow: hidden;
    .ant-modal-body{
      padding: 0 !important;
    }
    input{
      -webkit-appearance: none;
      font-size: $font24;
      font-weight: 400;
      height: 63px;
      font-weight: bold;
      color: $fontColor333;
      &::-webkit-input-placeholder{
        color: $fontColor9D9;
        line-height: 31px;
        font-weight: 400;
      }
      &::-moz-placeholder{ 
        color: $fontColor9D9;
        line-height: 31px;
        font-weight: 400;
      }
      &:-ms-input-placeholder {
        color: $fontColor9D9;
        line-height: 31px;
        font-weight: 400;
      }
    }
  }
}
.modal-content{
  width: 600px;
  @include flex(column, flex-start, flex-start);
  .close-btn{
    width: 100%;
    @include flex(row, flex-end, center);
    height: 64px;
    background: $bgColorF0F;
    img{
      cursor: pointer;
      margin-right: 32px;
      width: 32px;
      height: 32px;
    }
  }
  p{
    margin: 32px;
    margin-bottom: 0;
    font-size: $font20;
    color: $fontColor333;
    line-height: 26px;
  }
  .countdown-tip{
    position: absolute;
    z-index: 2;
    right: 32px;
    top: 32px;
    width: 120px;
    height: 63px;
    text-align: center;
    background: $bgColorE1E;
    border: 1px solid #D3D3D3;
    font-size: $font24;
    color: $fontColor447;
    line-height: 63px;
  }
  .footer-box{
    @include flex(row, flex-end, center);
    width: 100%;
    button{
      width: 160px;
      height: 79px;
      font-size: $font24;
      text-align: center;
      border-radius: 4px;
      &:first-child{
        margin-right: 32px;
        color: $fontColor447;
        border: 1px solid #447EDA;
      }
      &:last-child{
        color: $fontColorFFF;
        background: $bgColor447;
      }
    }
  }
}
