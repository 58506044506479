@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.program-made{
  position: relative;
  width: 100%;
  .bg-img{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 461px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    object-fit: cover;
  }
  .bg-mask{
    position: absolute;
    // z-index: -1;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    background: $bgColorF7F;
  }
  .made-box{
    cursor: pointer;
    position: relative;
    z-index: 10;
    @include flex(row, flex-end, flex-start);
    width: 1248px;
    margin: 0 auto;
    .form-container{
      @include flex(column, flex-start, center);
      margin-right: 13px;
      width: 744px;
      background: $bgColorFFF;
      box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.32);
      border-radius: 8px;
      padding: 32px;
      box-sizing: border-box;
      position: relative;
      .success-content{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @include flex(column, center, center);
        background: $bgColorFFF;
        img{
          width: 120px;
          height: 120px;
          margin-bottom: 40px;
        }
        .title{
          height: 24px;
          font-size: $font24;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: bold;
          color: $fontColor333;
          margin-bottom: 16px;
        }
        .desc{
          height: 20px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          margin-bottom: 40px;
        }
        button{
          padding: 0;
          width: 252px;
          height: 58px;
          border: 1px solid #447EDA;
          border-radius: 4px;
          line-height: 58px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor447;
        }
      }
      .made-desc{
        margin-bottom: 44px;
        font-size: $font26;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
        line-height: 30px;
      }
      .mobile-code{
        width: 100%;
        border: 1px solid #d9d9d9;
        height: 40px;
        span{
          height: 40px;
          line-height: 40px;
          margin-left: 16px;
          font-size: $font18;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor333;
        }
      }
      .check-title{
        margin-top: 24px;
        text-align: left;
        height: 20px;
        font-size: $font18;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
      }
      .check-box{
        @include flex(row, space-between, flex-start);
        margin-top: 16px;
      }
      .check-item{
        cursor: pointer;
        @include flex(row, flex-start, center);
        margin-bottom: 11px;
        &:hover{
          span{
            color: $fontColor447;
          }
          img{
            content: url('https://temp-cdn.huixinguanli.cn/static/media/images/icon_select_pitchon_highlight.png');
          }
          &.check-item-select{
              img{
              content: url('https://temp-cdn.huixinguanli.cn/static/media/images/icon_select_pitchon_s.png');
            }
          }
        }
        &.check-item-select{
          span{
            color: $fontColor447;
          }
        }
        img{
          width: 22px;
          height: 22px;
          margin-right: 6px;
        }
        span{
          height: 18px;
          font-size: $font18;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColor333;
          line-height: 18px;
        }
      }
      .protocol-box{
        margin-top: 15px;
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
        line-height: 16px;
        a{
          color: $fontColor447;
        }
      }
      .send-btn{
        display: inline-block;
        width: 100%;
        text-align: right;
        cursor: pointer;
        height: 40px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor447;
        line-height: 40px;
      }
    }
    .receive-box{
      @include flex(row, center, center);
      margin-top: 25px;
      button{
        padding: 0;
        width: 120px;
        height: 46px;
        background-color: $bgColor447;
        text-align: center;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 500;
        color: $fontColorFFF;
        line-height: 46px;
        border-color: transparent;
      }
    }
    input{
      font-size: 16px;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      &::-webkit-input-placeholder{
        // height: 16px;
        // line-height: 16px;
        color: $fontColor9D9;
        
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        // height: 16px;
        color: $fontColor9D9;
        // line-height: 16px;
      }
    }
    input[type=number] {  
      -moz-appearance:textfield;  
    }  
    input[type=number]::-webkit-inner-spin-button,  
    input[type=number]::-webkit-outer-spin-button {  
        -webkit-appearance: none;  
        margin: 0;  
    } 
  }
}