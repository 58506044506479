@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.login-container{
  @include flex(column, flex-start, center);
  // margin-top: 122px;
  .main{
    margin-top: 59px;
    margin-bottom: 60px;
    width: 324px;
    .title-box{
      @include flex(column, flex-start, center);
      p{
        height: 25px;
        font-size: $font26;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
        margin-bottom: 10px;
      }
      .tab-box{
        @include flex(row, center, center);
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
        height: 25px;
        :first-child:hover, :last-child:hover{
          cursor: pointer;
        }
        label{
          line-height: 25px;
          border-bottom: 2px solid transparent;
        }
        .active-label{
          color: $fontColor447;
          border-bottom: 2px solid #447EDA;
        }
      }
    }
    .input-phone-box{
      margin-top: 46px;
      @include flex(row, flex-start, flex-start);
      .select-box{
        display: flex;
        flex-direction: column;
        width: 33.9%;
        margin-right: 2.5%;
        select{
          border-radius: 0;
          border: none;
          width: 100%;
          height: 32px;
          appearance: none;
      // 　　-webkit-appearance: none; 
      // 　　-moz-appearance: none;
          background: url('https://temp-cdn.huixinguanli.cn/static/media/images/icon_select_arrow.png') no-repeat 90% center;
          border-bottom: 2px solid #D3D3D3;
          padding-left: 15px;
          box-sizing: border-box;
          &:hover{
            border-bottom-color: $bgColor447;
          }
        }
      }
      .input-box{
        display: flex;
        flex-direction: column;
        position: relative;
        width: 63.6%;
      }
    }
    .send-code-box{
      margin-top: 14px;
      @include flex(row, flex-start, flex-start);
      .input-code-box{
        flex: 1;
        margin-right: 21px;
        position: relative;
        @include flex(column, flex-start, flex-start);
      }
      .send-btn{
        cursor: pointer;
        margin-top: 9px;
        height: 16px;
        line-height: 16px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor1E4;
        text-align: right;
      }
    }
    .input-password-box{
      margin-top: 14px;
      position: relative;
      .input-password{
        position: relative;
        @include flex(column, flex-start, flex-start);
      }
      .show-password{
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 0;
        @include flex(row, center, center);
        img{
          width: 18px;
          height: 18px;
        }
        span{
          margin-left: 7px;
          height: 15px;
          line-height: 15px;
          font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColor333;
        }
      }
    }
    .protocol{
      margin-top: 18px;
      @include flex(row, center, center);
      img{
        width: 18px;
        height: 18px;
      }
      .protocol-content{
        margin-left: 7px;
        height: 14px;
        line-height: 14px;
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNLight;
        font-weight: 300;
        color: $fontColor333;
        .intro-title{
          color: $fontColor447;
        }
      }
    }
    .forgot-password{
      cursor: pointer;
      margin-top: 18px;
      height: 14px;
      line-height: 14px;
      font-size: $font14;
      font-family: $fontFamilySourceHanSansCNLight;
      font-weight: 300;
      color: $fontColor447;
    }
    .facebook-login{
      cursor: pointer;
      margin-top: 32px;
      width: 100%;
      @include flex(row, flex-start, center);
      height: 46px;
      background: $bgColor3B5;
      img{
        margin-left: 8px;
        // width: 24px;
        // height: 24px;
      }
      p{
        margin-left: 48px;
        height: 13px;
        line-height: 13px;
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColorFFF;
      }
    }
  }
  .input-control{
    width: 100%;
    height: 32px;
    border: none;
    border-bottom: 2px solid #D3D3D3;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    &:hover{
      border-bottom-color: $bgColor447;
    }
  }
  
  .input-label {
    display: block;
    position: absolute;
    z-index: 1;
    top: 9px;
    left: 15px;
    height: 14px;
    line-height: 14px;
    font-size: $font14;
    font-family: $fontFamilySourceHanSansCNLight;
    font-weight: 300;
    color: $fontColor9D9;
    transform-origin: left;
    -webkit-transform-origin: left;
    pointer-events: none;
    transition: all .3s;
  }
  
  .input-label-animate{
    -webkit-transform: scale(0.85,0.85) translate(0,-21px);
    transform: scale(0.85, 0.85) translate(0, -21px);
  }
  .input-label-normal{
    top: 9px;
    -webkit-transform: none;
    transform: none;
  }
  
  .error-msg{
    height: 14px;
    line-height: 14px;
    font-size: $font14;
    font-family: $fontFamilySourceHanSansCNLight;
    font-weight: 300;
    color: $fontColorFF0;
    margin-top: 8px;
    opacity: 0;
  }
  
  .error-msg-animate{
    -webkit-animation: fade-in 1s;
    animation: fade-in 1s;
    animation-fill-mode: forwards;
  }
  
  input {
    font-size: 16px;
    font-family: $fontFamilySourceHanSansCNMedium;
    font-weight: 500;
    color: $fontColor333;
    line-height: 14px;
    box-shadow: 0 0 0 60px white inset; 
  }
  input[type=number] {  -moz-appearance: textfield;  }  
  input[type=number]::-webkit-inner-spin-button,  
  input[type=number]::-webkit-outer-spin-button {  
      -webkit-appearance: none;  
      margin: 0;  
  } 
  
  .separator{
    margin-top: 24px;
    display: block;
    width: 100%;
    height: 30px;
    padding: 1px 0;
  }
  
  .separator-line{
    width: 100%;
    height: 2px;
    margin: 16px 0 0;
    background-color: $bgColorD3D;
    -webkit-animation: fade-in 1s;
    animation: fade-in 1s;
  }
  
  .separator-content {
    position: relative;
    top: -7px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    vertical-align: middle;
    -webkit-animation: slide-from-left 1s ;
    animation: slide-from-left 1s ;
  }
  
  .separator-content mark {
    background-color: #FFF;
    padding: 0 4px;
    -webkit-animation: appear 1s ;
    animation: appear 1s;
  }
}
@keyframes fade-in{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

// @-webkit-keyframes slide-from-left {
//   0% {
//     left: -25%;
//     color: transparent;
//   }
//   100% {
//     left: 0;
//     color: #151e27;
//   }
// }

@keyframes slide-from-left{
  0% {
    left: -25%;
    color: transparent;
  }
  100% {
    left: 0;
    color: #151e27;
  }
}

@keyframes appear{
  0% {
    color: transparent;
  }
  100% {
    color: #151e27;
  }
}