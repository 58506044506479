@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.about-container{
  @include flex(column, center, flex-start);
  .main{
    width: 100%;
    .header{
      margin-top: 60px;
      @include flex(column, flex-start, flex-start);
      :first-child{
        width: 100%;
        text-align: center
      }
      :last-child{
        margin: 32px auto 60px;
        width: 834px;
        font-size: $font16;
        font-family: $fontFamilyPingFangRegular;
        font-weight: 400;
        color: $fontColor636;
        line-height: 25px;
      }
    }
    .company-profile{
      @include flex(column, flex-start, center);
      padding: 80px 0px 12px;
      box-sizing: border-box;
      background-color: $bgColorFFF;
      img{
        margin-top: 56px;
        // width: 834px;
        // height: 336px;
      }
      .profile-list{
        display: flex;
        flex-direction: column;
        padding: 20px 0 60px;
        box-sizing: border-box;
        width: 834px;
        .profile-item{
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          box-sizing: border-box;
          span{
            height: 45px;
            font-size: $font32;
            font-family: $fontFamilyPingFangSemibold;
            color: $fontColor303;
            line-height: 45px;
            letter-spacing: 2px;
          }
          p{
            margin-top: 32px;
            font-size: $font16;
            font-family: $fontFamilyPingFangRegular;
            font-weight: 400;
            color: $fontColor636;
            line-height: 25px;
          }
        }
      }
    }
    .company-achievement{
      @include flex(column, flex-start, center);
      box-sizing: border-box;
      background-color: $bgColorF0F;
      padding: 48px 0 52px;
      .title{
        height: 30px;
        line-height: 30px;
        font-size: $font30;
        font-family: $fontFamilySourceHanSansCNBold;
        font-weight: bold;
        color: $fontColor333;
      }
      .achievement-list{
        @include flex(row, center, center);
        margin-top: 57px;
        .achievement-item{
          @include flex(column, center, center);
          margin-right: 128px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          &:last-child{
            margin-right: 0px;
          }
          .top{
            @include flex(row, flex-start, flex-end);
            :first-child{
              // height: 63px;
              font-size: $font80;
              line-height: 63px;
              font-weight: 400;
              color: $bgColor437;
            }
            :last-child{
              margin-left: 2px;
              color: $bgColor437;
            }
          }
          :last-child{
            margin-top: 20px;
            // height: 20px;
            line-height: 20px;
            font-weight: 400;
            color: $fontColor333;
          }
        }
      }
    }
    .teams-box{
      @include flex(column, flex-start, center);
      padding: 80px 0px 10px;
      box-sizing: border-box;
      background-color: $bgColorF0F;
      width: 100%;
      .teams-list{
        margin-top: 54px;
        @include flex(row, flex-start, center);
        flex-wrap: wrap;
        .teams-item{
          @include flex(column, center, center);
          // background: #FFFFFF;
          // border-radius: 10px;
          overflow: hidden;
          margin-right: 24px;
          margin-bottom: 68px;
          &:last-child{
            margin-right: 0px;
          }
          // &:nth-child(3n+3){
          //   margin-right: 0px;
          // }
          .member-cover{
            width: 200px;
            height: 200px;
            z-index: 10;
          }
          .member-info-box{
            margin-top: -33px;
            width: 389px;
            background: #FFFFFF;
            border-radius: 10px;
            @include flex(column, flex-start, flex-start);
            .member-name{
              width: 100%;
              text-align: center;
              margin-top: 47px;
              font-size: $font20;
              font-family: $fontFamilySourceHanSansCNBold;
              font-weight: bold;
              color: $fontColor333;
              line-height: 20px;
            }
            .member-position{
              width: 100%;
              text-align: center;
              margin-top: 4px;
              font-size: $font12;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor447;
              line-height: 12px;
            }
            .member-intro{
              margin: 14px 24px 26px;
              font-size: $font18;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor6E6;
              line-height: 30px;
            }
          }
        }
      }
    }
    .subtitle{
      text-align: center;
      height: 56px;
      font-size: $font40;
      font-family: $fontFamilyPingFangSemibold;
      font-weight: 600;
      color: $fontColor303;
      line-height: 56px;
      letter-spacing: 3px;
    }
  }
}