@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.payment-container{
  @include flex(column, flex-start, center);
  .top-header{
    @include flex(row, center, center);
    width: 100%;
    background: $bgColorFDF;
    padding: 24px 0 56px;
    box-sizing: border-box;
    .header-item{
      @include flex(column, center, center);
      position: relative;
      &::before{
        content: '投保詳情';
        position: absolute;
        width: 100px;
        top: 40px;
        height: 24px;
        font-size: $font18;
        color: $fontColor333;
        line-height: 24px;
        text-align: center;
      }
      &:first-child{
        &::before{
          content: '即時報價';
        }
      }
      &.header-item-preview{
        &::before{
          content: '預覽及付款';
        }
      }
      &:last-child{
        &::before{
          content: '確認';
        }
      }
      .receive-status{
        @include flex(column, center, center);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $bgColor1E4;
        &.receive-status-n{
          background: $bgColor9D9;
        }
        img{
          width: 20px;
          height: 20px;
        }
        span{
          height: 24px;
          font-size: $font18;
          color: $fontColor333;
          line-height: 24px;
          font-weight: bold;
          color: $fontColorFFF;
        }
      }
    }
    .progress-line{
      width: 291px;
      height: 2px;
      background: $bgColor1E4;
    }
  }
  #payment-form{
    padding-bottom: 64px;
    iframe {
      /* This fixes a mobile Safari bug */
      // height: 38px !important;
    }
    .input-box {
      padding: 32px 32px 40px 32px;
      background: #FAFAFA;
      border: 1px solid #1E4994;
      
      label {
        display: block;
        color: #333;
        font-size: 20px;
        margin-bottom: 16px;
      }
    }
    
    .date-and-code {
      display: flex;
      margin-bottom: 8px;
    }
    
    .date-and-code > div:nth-child(1) {
      width: 55.715%;
    }
    .date-and-code > div:nth-child(2) {
      width: 45.719%;
    }
    
    .input-container {
      position: relative;
      display: flex;
    }
    .icon-container:last-child {
      right: 0;
    }
    .icon-container.payment-method {
      right: 0;
    }
    
    .input-container.card-number {
      margin-bottom: 8px;
    }
    .input-container.expiry-date {
      margin-right: 8px;
    }
    
    .card-number-frame,
    .expiry-date-frame,
    .cvv-frame {
      flex: 1 1 auto;
      padding-left: 40px;
    }
    
    div + button {
      margin-top: 8px;
    }
    
    .icon-container {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      width: 26px;
      margin: 0 7px;
    }
    
    .icon-container.payment-method {
      transform: translateY(-50%) rotateY(90deg);
      transition: opacity 0.15s ease-out;
      opacity: 0;
      top: 50%;
    }
    
    .icon-container.payment-method.show {
      opacity: 1;
      transition: all 0.4s ease-out;
      transform: translateY(-50%) rotateY(0deg);
    }
    
    .icon-container.payment-method img {
      width: 100%;
    }
    
    [id$="-error"] {
      display: none;
    }
    
    .frame {
      opacity: 0;
    }
    
    .frame--activated {
      height: 63px;
      background: #FFFFFF;
      border: 1px solid #D3D3D3;
      opacity: 1;
    }
    
    .frame--activated.frame--focus {
      border: solid 1px #13395e;
      box-shadow: 0 2px 5px 0 rgba(19, 57, 94, 0.15);
    }
    
    .frame--activated.frame--invalid {
      border: solid 1px #d96830;
      box-shadow: 0 2px 5px 0 rgba(217, 104, 48, 0.15);
    }
    
    .error-message {
      display: block;
      color: #c9501c;
      font-size: 0.9rem;
      margin: 8px 0 0 1px;
      font-weight: 300;
    }
    
    #pay-button {
      border: none;
      color: #fff;
      font-weight: 500;
      width: 320px;
      height: 79px;
      display: block;
      margin: 56px auto 0;
      background-color: #447EDA;
      border-radius: 4px;
      font-size: 20px;
    }
    
    #pay-button:disabled {
      background-color: #42566b;
    }
    
    #pay-button:not(:disabled) {
      cursor: pointer;
    }
    
    .success-payment-message {
      color: #13395e;
      line-height: 1.4;
    }
    .token {
      color: #b35e14;
      font-size: 0.9rem;
      font-family: monospace;
    }
    
    /**
    IE11-targeted optimisations
     */
    _:-ms-fullscreen,
    :root .icon-container {
      display: block;
    }
    
    _:-ms-fullscreen,
    :root .icon-container img {
      top: 50%;
      -ms-transform: translateY(-50%);
      position: absolute;
    }
    
    _:-ms-fullscreen,
    #icon-card-number,
    _:-ms-fullscreen,
    #icon-expiry-date,
    _:-ms-fullscreen,
    #icon-cvv {
      left: 7px;
    }
    
    #checkout-frames-card-number::-ms-clear {
      display: none;
    }
    



    // .one-liner{
    //   display: flex;
    //   flex-direction: column;
    //   .pay-button{
    //     border: none;
    //     border-radius: 3px;
    //     color:#FFF;
    //     font-weight: 500;
    //     height: 40px;
    //     width: 100%;
    //     background-color: #13395E;
    //     box-shadow: 0 1px 3px 0 rgba(19,57,94,0.4);
    //     &:active{
    //       background-color:#0B2A49;
    //       box-shadow: 0 1px 3px 0 rgba(19,57,94,0.4)
    //     }
    //     &:hover{
    //       background-color:#15406B;
    //       box-shadow:0 2px 5px 0 rgba(19,57,94,0.4)
    //     }
    //     &:disabled{
    //       background-color:#697887;
    //       box-shadow: none
    //     }
    //     &:not(:disabled){
    //       cursor:pointer
    //     }
    //   }
    //   .card-frame{
    //     border: solid 1px #13395E;
    //     border-radius: 3px;
    //     width: 100%;
    //     margin-bottom: 8px;
    //     height: 40px;
    //     box-shadow: 0 1px 3px 0 rgba(19,57,94,0.2);
    //     .frame--rendered{
    //       opacity:1;
    //       .frame--focus{
    //         border: solid 1px #13395E;
    //         box-shadow:0 2px 5px 0 rgba(19,57,94,0.15)
    //       }
    //       .frame--invalid{
    //         border: solid 1px #D96830;
    //         box-shadow:0 2px 5px 0 rgba(217,104,48,0.15)
    //       }
    //     }
    //   }
    // }
    // .success-payment-message{
    //   color: #13395E;
    //   line-height:1.4
    // }
  }
  .loading-box{
    @include flex(column, center, center);
    position: fixed;
    z-index: 10;
    top: 112px;
    width: 100%;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
  }
  .form-body {
    width: 1272px;
    // height: 1287px;
    .form-body-content {
      padding: 0 88px;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    }
    .payment-title {
      padding: 56px 0 24px;
      display: flex;
      align-items: center;
      &-img {
        width: 52px;
        height: 52px;
      }
      &-text {
        font-size: 40px;
        color: #1E4994;
        font-weight: bold;
      }
      .line {
        margin-left: 32px;
        height: 2px;
        flex: 1;
        background: #1E4994;
      }
      &.payment-title-black {
        .payment-title-text {
          color: #333;
        }
        .line {
          background: #333;
        }
      }
    }
    .form-body-content-tab {
      .payment-title {
        padding-top: 30px;
      }
      &:first-child {
        .payment-title {
          padding-top: 56px;
        }
      }
    }

    .payment-details {
      display: flex;
      flex-wrap: wrap;
      color: #333;
      font-size: 20px;
      &.premium-payable {
        padding-top: 40px;
        border-top: 2px solid #333;
      }
      &-item {
        width: 50%;
        margin-bottom: 32px;
        &.width-100 {
          width: 100%;
        }
      }
      .payment-details-content {
        font-size: 24px;
        font-weight: 600;
        margin-top: 10px;
      }
    }
    .upgrade-guarantee {
      margin-bottom: 36px;
      .upgrade-guarantee-title {
        font-size: 24px;
        // font-weight: bold;
        // color: #0000FF;
        line-height: 1;
      }
      .ant-radio-group {
        .ant-radio-wrapper {
          display: block;
          line-height: 1;
          margin-top: 16px;
          font-size: 24px;
          .additional-before {
            color: #333;
          }
          .additional-premium {
            color: #FF0000;
          }
          .ant-radio-inner {
            width: 24px;
            height: 24px;
            &::after {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
      &-tips {
        color: #f00;
        font-size: 20px;
        margin-bottom: 32px;
      }
    }
    
    .payment-authorization {
      color: #333;
      &-title {
        font-size: 40px;
      }
      &-agreement-box {
        font-size: 20px;
        font-weight: 600;
        >div {
          margin-top: 24px;
        }
      }
      .address-details-box {
        font-size: 20px;
        margin-top: 56px;
        padding-bottom: 56px;
        .address-details {
          margin-top: 16px;
        }
      }
    }

    .amount-payable {
      padding-top: 8px;
      margin-bottom: 56px;
      &-insurance {
        height: 95px;
        line-height: 95px;
        background: #447EDA;
        border-radius: 8px 8px 0px 0px;
        font-weight: bold;
        color: #FFFFFF;
        font-size: 24px;
        padding: 0 32px;
      }
      &-price {
        background: #E1EAF9;
        height: 116px;
        line-height: 116px;
        font-size: 40px;
        color: #1E4994;
        text-align: center;
      }
    }
  }
  .checkout-platform {
    width: 1096px;
    height: 308px;
    background: #FAFAFA;
    border: 1px solid #1E4994;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    color: #333;
    line-height: 1.7;
    padding-top: 50px;
    .checkout-platform-title {
      font-size: 40px;
      color: #1E4994;
    }
    .checkout-platform-shop-name {
      font-size: 34px;
    }
  }

  .confirm-pay {
    margin-top: 64px;
    margin-bottom: 88px;
    text-align: right;
    .pay-button {
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      width: 320px;
      height: 79px;
      background: #447EDA;
      border-radius: 4px;
      border: none;
    }
    .previous-step {
      width: 320px;
      height: 79px;
      border-radius: 4px;
      border: 1px solid #447EDA;
      vertical-align: bottom;
      background: #fff;
      font-size: 24px;
      color: #447EDA;
      margin-right: 32px;
    }
  }
}
