@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.exclusive-container{
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  .service-process{
    @include flex(column, flex-start, center);
    margin-top: 78px;
    .service-title{
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: bold;
      color: $fontColor333;
      :last-child{
        margin-left: 4px;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: normal;
      }
    }
    .process-list{
      margin-top: 60px;
      margin-bottom: 80px;
      @include flex(row, center, center);
      .process-item{
        position: relative;
        @include flex(column, center, center);
        width: 170px;
        height: 170px;
        border: 2px solid #D3D3D3;
        border-radius: 4px;
        margin-right: 93px;
        &::after{
          position: absolute;
          top: 70px;
          right: -57px;
          content: '';
          width: 19px;
          height: 30px;
          @include backgroundImg(19px, 30px, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_exclusive_custom_right_arrow.png', cover);
        }
        &:last-child{
          margin-right: 0;
          &::after{
            display: none;
          }
        }
        img{
          width: 50px;
          height: 50px;
        }
        span{
         margin-top: 18px;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: bold;
        color: $fontColor333;
        line-height: 36px;
        }
      }
    }
  }
}