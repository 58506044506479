@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.overlap-contanier{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1001;
  .overlap-content {
    @include flex(row, flex-start, center);
    float: right;
    cursor: pointer;
  }
}