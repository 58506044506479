@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.news-detail-container{
  display: flex;
  flex-direction: column;
  background-color: $bgColorFFF;
  // margin-top: 122px;
  .nav-back{
    background-color: $bgColorF0F;
    height: 60px;
    display: flex;
    flex-direction: row;
    padding: 17px 76px 0;
    box-sizing: border-box;
    font-size: $font16;
    font-family: $fontFamilyPingFangRegular;
    font-weight: 400;
    color: $fontColor303;
    line-height: 22px;
    :first-child{
      cursor: pointer;
    }
    :last-child{
      color: $fontColor2C7;
    }
    span{
      height: 22px;
    }
    img{
      margin: 4px 8px 0;
      width: 18px;
      height: 18px;
    }
  }
  .main{
    display: flex;
    flex-direction: column;
    background-color: $bgColorFFF;
    padding: 50px 70px 80px;
    box-sizing: border-box;
    width: 1248px;
    margin-left: auto;
    margin-right: auto;
    .news-title{
      margin-left: 6px;
      // height: 45px;
      font-size: $font32;
      font-family: $fontFamilyPingFangSemibold;
      font-weight: 600;
      color: $fontColor303;
      line-height: 45px;
      letter-spacing: 2px;
    }
    .news-info{
      @include flex(row, flex-start, center);
      flex-wrap: wrap;
      margin-left: 6px;
      margin-top: 6px;
      .news-date-time, .news-address, .news-quota{
        @include flex(row, flex-start, center);
        margin-right: 32px;
        margin-top: 10px;
      }
      img{
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      p{
        height: 25px;
        font-size: $font16;
        font-family: $fontFamilyPingFangRegular;
        font-weight: 400;
        color: $fontColor303;
        line-height: 25px;
      }
      .highlight-quota{
        font-family: $fontFamilyPingFangSemibold;
        font-weight: 600;
        color: $fontColorFD7;
      }
    }
    .news-cover{
      margin-top: 32px;
      width: 1140px;
      max-height: 676px;
      // height: 676px;
      // object-fit: cover;
    }
    .detail-content{
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      font-family: $fontFamilyPingFangRegular;
      line-height: 25px;
    }
    .news-bottom{
      margin-top: 50px;
      @include flex(row, center, flex-start);
      .news-apply{
        cursor: pointer;
        margin-top: 32px;
        width: 206px;
        height: 50px;
        background: linear-gradient(90deg, #2876E2 0%, #3F8EFC 100%);
        border-radius: 6px;
        @include flex(row, center, center);
        p{
          height: 22px;
          font-size: $font16;
          font-family: $fontFamilyPingFangRegular;
          font-weight: 400;
          color: $fontColorFFF;
          line-height: 22px;
        }
        img{
          margin-left: 8px;
          width: 14px;
          height: 11px;
        }
      }
    }
  }
  .loading-box{
    @include flex(column, center, center);
    height: 75vh;
    position: static;
  }
}