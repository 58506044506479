@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.product-item{
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
  background: $bgColorFFF;
  border: 1px solid #D3D3D3;
  padding: 24px 16px;
  box-sizing: border-box;
  &:hover{
    box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.32);
  }
  .top{
    @include flex(row, space-between, center);
    font-size: $font20;
    font-family: $fontFamilySourceHanSansCNMedium;
    font-weight: 500;
    color: $fontColor333;
    padding: 0 8px;
    box-sizing: border-box;
    .name{
      @include ellipsis();
      width: 80%;
      height: 20px;
      line-height: 20px;
    }
    .compare-box{
      cursor: pointer;
      @include flex(row, flex-start, center);
      img{
        width: 22px;
        height: 22px;
      }
      p{
        margin-left: 7px;
        height: 20px;
        line-height: 20px;
      }
    }
  }
  .product-info{
    width: 100%;
    margin-top: 30px;
    @include flex(row, space-between, flex-start);
    padding: 0 8px;
    box-sizing: border-box;
    .product-detail{
      flex: 1;
      @include flex(row, flex-start, flex-start);
      img{
        width: 127px;
        height: 77px;
        margin-right: 16px;
      }
      p{
        @include ellipsis(2);
        line-height: 22px;
        font-size: $font20;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
      }
    }
    .product-price{
      margin-left: 50px;
      margin-top: 28px;
      height: 26px;
      line-height: 26px;
      font-size: $font26;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColor447;
    }
  }
  .tag-box{
    width: 100%;
    padding: 16px 8px 8px;
    box-sizing: border-box;
    @include flex(row,  flex-start, center);
    flex-wrap: wrap;
    border-bottom: 1px solid #D3D3D3;
    span{
      text-align: center;
      // border: 1px solid #F2A915;
      padding: 7px 8px;
      box-sizing: border-box;
      font-size:$font20;
      font-family: $fontFamilySourceHanSansCNRegular;
      font-weight: 400;
      color: $fontColor333;
      line-height: 19px;
      margin-right: 8px;
      margin-bottom: 16px;
      background-color: $bgColorFCE;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .bottom{
    margin-top: 24px;
    @include flex(row, space-between, flex-start);
    padding: 0 8px 0 17px;
    box-sizing: border-box;
    .insured-amount{
      @include flex(row, flex-start, center);
      flex: 1;
      .insured-box{
        @include flex(column, center, center);
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 400;
        margin-right: 2%;
        &:last-child{
          margin-right: 0;
        }
        :first-child{
          @include ellipsis();
          height: 19px;
          line-height: 19px;
          font-size: $font20;
          color: $fontColor447;
        }
        :last-child{
          @include ellipsis();
          margin-top: 14px;
          height: 16px;
          line-height: 16px;
          font-size: $font16;
          color: $fontColor333;
        }
      }
    }
    .check-detail{
      margin-left: 2%;
      cursor: pointer;
      margin-top: 3px;
      @include flex(row, flex-end, center);
      span{
        cursor: pointer;
        margin-left: 8px;
        width: 120px;
        height: 46px;
        font-size: 16px;
        background: $bgColor447;
        text-align: center;
        line-height: 46px;
        font-family: SourceHanSansCN-Medium;
        font-weight: 500;
        color: $fontColorFFF;
        a {
          display: block;
          color: $fontColorFFF;
          &:hover {
            color: $fontColorFFF;
          }
        }
      }
    }
  }
}