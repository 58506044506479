@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.compare-container{
  background-color: $bgColorEBE;
  .banner-box{
    @include backgroundImg(100%, 100%, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_banner_product_presentation.png', cover);
    @include flex(column, center, flex-start);
    height: 320px;
    padding-left: calc(100vw/2 - 624px);
    .title{
      height: 49px;
      line-height: 49px;
      font-size: $font50;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      color: $fontColorFFF;
    }
    .desc{
      margin-top: 24px;
      width: 608px;
      line-height: 23px;
      font-size: $font20;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 500;
      color: $fontColorFFF;
    }
  }
  .tab-box{
    z-index: 1000;
    position: sticky;
    top: 122px;
    @include flex(row, center, center);
    background-color: $bgColorEBE;
    .tab-item{
      cursor: pointer;
      @include flex(column, center, center);
      padding: 20px 0 17px;
      box-sizing: border-box;
      border-bottom: none;
      margin-right: 120px;
      position: relative;
      &:last-child{
        margin-right: 0;
      }
      &.tab-item-active::before{
        // border-bottom: 4px solid #447EDA;
        width: 100%;
        left: 0;
        transition-delay: 0.1s;
        z-index: -1;
        transition: 0.2s all linear;
      }
      &::before{
        content: "";
        position: absolute;
        bottom: 0;
        // left: 50%;
        left: 0;
        width: 0;
        height: 4px;
        border-bottom: 4px solid #447EDA;
        transition: 0.2s all linear;
      }
      // &:hover::before {
      //   width: 100%;
      //   left: 0;
      // }
      // &:hover ~ &::before {
      //   left: 0;
      // }
      img{
        width: 48px;
        height: 38px;
      }
      p{
        margin-top: 15px;
        height: 24px;
        line-height: 24px;
        // font-size: $font24;
        font-family: $fontFamilySourceHanSansCNBold;
        font-weight: bold;
        color: $bgColorFFF;
      }
    }
  }
  .title-box{
    background-color: $bgColorF7F;
    @include flex(row, center, center);
    height: 39px;
    line-height: 39px;
    // font-size: $font40;
    font-family: $fontFamilySourceHanSansCNBold;
    font-weight: bold;
    color: $fontColor333;
    padding: 79px 0;
    :last-child{
      margin-left: 4px;
      font-family: $fontFamilySourceHanSansCNRegular;
      font-weight: 400;
    }
  }
  .medical-illness-box{
    background: $bgColorFFF;
    @include flex(column, center, center);
    padding: 79px 0 80px;
  }
  .savings-box{
    @include flex(column, center, center);
    padding: 76px 0 81px;
    .main{
      width: 1247px;
      @include flex(column, flex-start, flex-start);
      .top-box{
        @include flex(row, flex-start, center);
        p{
          margin-left: 17px;
          height: 39px;
          line-height: 39px;
          font-size: $font40;
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
          color: $fontColor447;
        }
      }
      .content{
        margin-top: 64px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 8px 8px 16px 16px;
        padding: 47px 40px;
        box-sizing: border-box;
        @include flex(column, flex-start, flex-start);
        .tag-box{
          width: 100%;
          @include flex(column, flex-start, flex-start);
          .tag-item{
            @include flex(row, flex-start, flex-start);
            margin-bottom: 16px;
            span{
              display: inline-block;
              margin-top: 10px;
              width: 10px;
              height: 10px;
              background: $bgColor447;
              border-radius: 5px;
              margin-right: 8px;
            }
            P{
              flex: 1;
              font-size: $font20;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor333;
              line-height: 30px;
            }
          }
        }
        .bottom-box{
          margin-top: 50px;
          width: 100%;
          @include flex(row, center, center);
          .bottom-item{
            @include flex(column, flex-start, flex-start);
            width: 480px;
            height: 272px;
            background: $bgColorEBE;
            box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.16);
            border-radius: 8px;
            padding: 0 17px 0 24px;
            box-sizing: border-box;
            margin-right: 32px;
            &:last-child{
              margin-right: 0;
            }
            .title{
              width: 100%;
              text-align: center;
              margin-top: 38px;
              // height: 26px;
              line-height: 26px;
              font-size: $font26;
              font-family: $fontFamilySourceHanSansCNBold;
              font-weight: bold;
              color: $fontColor333;
            }
            .sub-title{
              margin: 22px 8px 3px 16px;
              text-align: center;
              font-size: $font20;
              font-family: $fontFamilySourceHanSansCNBold;
              font-weight: bold;
              color: $fontColor333;
              line-height: 30px;
            }
            .desc-box{
              margin-top: 22px;
              @include flex(row, flex-start, flex-start);
              span{
                display: inline-block;
                margin-top: 9px;
                width: 12px;
                height: 12px;
                background: $bgColor447;
              }
              p{
                width: 100%;
                text-align: center;
                margin-left: 8px;
                font-size: $font20;
                font-family: $fontFamilySourceHanSansCNRegular;
                font-weight: 400;
                color: $fontColor333;
                line-height: 30px;
                white-space: pre-line;
                // background-color: antiquewhite;
              }
            }
          }
        }
      }
    }
  }
  .general-insurance-box{
    background: $bgColorF7F;
    @include flex(column, center, center);
    padding: 78px 0 80px;
    position: relative;
    .general-insurance-bg{
      position: absolute;
      top: 0;
      left: 0;
      width: 52%;
      height: 340px;
      background: $bgColorFFF;
      border-radius: 0px 0px 8px 8px;
    }
    .main{
      z-index: 1;
      width: 1247px;
      @include flex(column, flex-start, flex-start);
      .title{
        margin-left: 679px;
        height: 49px;
        line-height: 49px;
        font-size: $font50;
        font-family: $fontFamilySourceHanSansCNBold;
        font-weight: bold;
        color: $fontColor447;
      }
      .insurance-list{
        width: 100%;
        @include flex(row, center, center);
        margin-top: 64px;
        .insurance-item{
          width: 394px;
          height: 322px;
          background: $bgColorFFF;
          box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.16);
          border-radius: 8px;
          @include flex(column, flex-start, center);
          padding: 0 29px;
          box-sizing: border-box;
          margin-right: 32px;
          &:last-child{
            margin-right: 0px;
          }
          img{
            margin-top: 48px;
          }
          .name{
            margin-top: 47px;
            font-size: $font26;
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
            color: $fontColor333;
          }
          .desc{
            text-align: center;
            margin-top: 23px;
            line-height: 30px;
            font-size: $font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
          }
        }
      }
    }
  }
  .contact-now{
    position: relative;
    width: 1247px;
    margin: 165px auto;
    &:hover{
      .form-container{
        box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.64);
      }
    }
    .bg-box{
      width: 1247px;
      height: 514px;
      @include backgroundImg(100%, 514px, 'https://temp-cdn.huixinguanli.cn/static/media/images/icon_contact_us_form_bg.png', cover);
      .content{
        @include flex(column, center, flex-start);
        height: 100%;
        margin-left: 79px;
        .title{
          font-family: $fontFamilySourceHanSansCNBold;
          font-weight: bold;
          color: $fontColorFFF;
          height: 56px;
          line-height: 56px;
          font-size: $font58;
        }
        .sub-title{
          margin-top: 12px;
          line-height: 49px;
          font-size: $font20;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
          color: $fontColorFFF;
          white-space: pre;
        }
      }
    }
    .form-container{
      position: absolute;
      top: -64px;
      right: 80px;
      width: 400px;
      height: 642px;
      background: $bgColorFFF;
      border-radius: 8px;
      padding: 0 32px;
      .tabs-box{
        margin-top: 31px;
        @include flex(row, flex-start, center);
        height: 26px;
        line-height: 26px;
        // font-size: $font26;
        font-family: $fontFamilySourceHanSansCNBold;
        font-weight: bold;
        color: $fontColor333;
        :last-child{
          margin-left: 4px;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 400;
        }
      }
      .protocol-box{
        font-size: $font14;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
        line-height: 16px;
        a{
          color: $fontColor447;
        }
      }
      textarea{
        resize: none;
        height: 70px;
      }
      input, textarea{
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
        &::-webkit-input-placeholder{
          color: $fontColor9D9;
        }
        &::-moz-placeholder{   /* Mozilla Firefox 19+ */
          color: $fontColor9D9;
        }
      }
      input[type=number] {
        -moz-appearance:textfield;
      }
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
      .submit-btn{
        @include flex(row, center, center);
        // margin-top: 24px;
        button{
          padding: 0;
          width: 100%;
          height: 46px;
          background-color: $bgColor447;
          text-align: center;
          font-size: $font18;
          font-family: $fontFamilySourceHanSansCNRegular;
          font-weight: 500;
          color: $fontColorFFF;
          line-height: 46px;
          border-color: transparent;
        }
      }
    }
  }
  // .main{
  //   margin-top: 45vh;
  //   @include flex(column, flex-start, center);
  //   .select-compare{
  //     margin-top: 60px;
  //     margin-bottom: 60px;
  //     @include flex(column, flex-start, flex-start);
  //     width: 540px;
  //     background: $bgColorFFF;
  //     border: 1px solid #D3D3D3;
  //     display: none;
  //     .title{
  //       margin-left: -1px;
  //       width: 540px;
  //       height: 56px;
  //       background: $bgColor1E4;
  //       line-height: 56px;
  //       text-align: center;
  //       font-size: $font26;
  //       font-family: $fontFamilySourceHanSansCNMedium;
  //       font-weight: 500;
  //       color: $fontColorFFF;
  //     }
  //     .form-container{
  //       @include flex(column, flex-start, center);
  //       padding: 24px 38px 0;
  //       width: 100%;
  //       box-sizing: border-box;
  //       .form-label{
  //         margin-bottom: 7px;
  //         text-align: left;
  //         width: 100%;
  //         height: 19px;
  //         font-size: $font20;
  //         font-family: $fontFamilySourceHanSansCNRegular;
  //         font-weight: 400;
  //         color: $fontColor333;
  //         line-height: 19px;
  //       }
  //     }
  //     .annuity-insurers{
  //       pointer-events: none;
  //       width: 100%;
  //       @include flex(column, flex-start, flex-start);
  //       padding: 0 38px;
  //       margin-bottom: 24px;
  //       height: 34px;
  //       .swiper-slide-item{
  //         background-color: $bgColor000;
  //         width: 80px;
  //         height: 34px;
  //       }
  //     }
  //   }

  // }
}
.category-container{
  width: 1247px;
  @include flex(column, flex-start, flex-start);
  .top-box{
    @include flex(row, flex-start, center);
    p{
      margin-left: 17px;
      height: 39px;
      line-height: 39px;
      font-size: $font40;
      font-family: $fontFamilySourceHanSansCNBold;
      font-weight: bold;
      color: $fontColor447;
    }
  }
  .tag-box{
    width: 100%;
    @include flex(column, flex-start, flex-start);
    .tag-item{
      margin-left: 40px;
      @include flex(row, flex-start, flex-start);
      margin-bottom: 16px;
      span{
        margin-top: 10px;
        display: inline-block;
        width: 10px;
        height: 10px;
        background: $bgColor447;
        border-radius: 5px;
        margin-right: 8px;
      }
      P{
        flex: 1;
        font-size: $font20;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
        line-height: 30px;
      }
    }
  }
  .bottom-box{
    @include flex(column, flex-start, flex-start);
    width: 100%;
    background: $bgColorEBE;
    box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.16);
    border-radius: 8px 8px 16px 16px;
    padding: 8px 57px 46px;
    box-sizing: border-box;
    margin-top: 31px;
  }
}
.list-container{
  width: 100%;
  @include flex(column, flex-start, flex-start);
  .list-title{
    margin-top: 40px;
    @include flex(column, flex-start, flex-start);
    font-family: $fontFamilySourceHanSansCNBold;
    font-weight: bold;
    color: $fontColor333;
    :first-child{
      height: 29px;
      line-height: 29px;
      font-size: $font26;
    }
    :last-child{
      margin-top: 13px;
      margin-bottom: 24px;
      height: 22px;
      line-height: 22px;
      font-size: $font20;
    }
  }
  .list-box{
    @include flex(column, flex-start, flex-start);
    .list-item{
      margin-bottom: 24px;
      @include flex(row, flex-start, flex-start);
      span{
        display: inline-block;
        margin-top: 7px;
        width: 12px;
        height: 12px;
        background: $bgColor447;
      }
      p{
        margin-left: 9px;
        font-size: $font20;
        font-family: $fontFamilySourceHanSansCNRegular;
        font-weight: 400;
        color: $fontColor333;
        line-height: 26px;
      }
    }
  }
}