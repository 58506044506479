@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.activity-detail-container{
  display: flex;
  flex-direction: column;
  background-color: $bgColorF7F;
  .main{
    display: flex;
    flex-direction: column;
    .detail-content{
      width: 1246px;
      margin-left: auto;
      margin-right: auto;
      .activity-cover-box{
        // height: 650px;
        .activity-cover{
          width: 100%;
          height: 650px;
          // object-fit: cover;
        }
      }
      .activity-title{
        width: 100%;
        margin-top: 39px;
        font-size: $font26;
        font-family: $fontFamilySourceHanSansCNBold;
        font-weight: bold;
        color: $fontColor1E4;
        line-height: 34px;
      }
      .detail-desc{
        display: flex;
        flex-direction: column;
        margin-top: 39px;
        font-size: $font20;
        font-family: $fontFamilySourceHanSansCNRegular;
        // color: $fontColor1E4;
        line-height: 30px;
      }
    }
    .activity-bottom{
      margin: 80px 0px;
      width: 100%;
      position: relative;
      .bottom-bg{
        width: 1751px;
        right: 0;
        top: 82px;
        bottom: 80px;
        position: absolute;
        background-color: $bgColorEBE;
      }
      .bottom-content{
        width: 1286px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        .bg-cover{
          position: absolute;
        }
        .activity-info{
          position: relative;
          width: 938px;
          height: 618px;
          @include flex(column, flex-start, flex-start);
          padding: 0 42px;
          box-sizing: border-box;
          border-radius: 10px;
          .activity-cover{
            position: absolute;
            width: 468px;
            height: 270px;
            bottom: 86px;
            right: -308px;
            border-radius: 8px;
          }
          &:hover{
            .activity-cover{
              box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.64);
            }
          }
          .title{
            margin-top: 51px;
            @include ellipsis(2);
            line-height: 50px;
            font-size: $font40;
            font-family: $fontFamilySourceHanSansCNBold;
            font-weight: bold;
            color: $fontColorFFF;
          }
          .activity-date{
            margin-top: 24px;
            font-size: $font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColorFFF;
            line-height: 30px;
          }
          .info-box{
            margin-top: 48px;
            @include flex(row, flex-start, flex-start);
            &:first-child{
              margin-top: 32px;
            }
            img{
              width: 26px;
              height: 26px;
              margin-right: 26px;
            }
            p{
              margin-right: 150px;
              @include ellipsis(2);
              font-size: $font20;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColorFFF;
              line-height: 26px;
            }
            .limit-people{
              @include flex(row, flex-start, flex-end);
              color: $fontColorFFF;
              p{
                margin-right: 0px;
                font-size: $font26;
              }
              span{
                font-size: $font20;
                line-height: 20px;
              }
            }
          }
          .sign-up-btn{
            display: inline-block;
            margin-top: 40px;
            margin-left: 52px;
            padding: 0 36px;
            height: 48px;
            background: #F7F8F9;
            border-radius: 4px;
            line-height: 48px;
            font-size: $font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor1E4;
          }
        }
      }
    }
  }
  .loading-box{
    @include flex(column, center, center);
    position: static;
    height: 75vh;
  }
}