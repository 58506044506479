@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.gift-insurance{
  display: flex;
  flex-direction: column;
  .top-header{
    display: flex;
    flex-direction: column;
    &>img{
      width: 100%;
      height: 320px;
      // object-fit: cover;
    }
    .advantage-box{
      width: 100%;
      background-color: $bgColorFDF;
      @include flex(row, flex-start, center);
      .advantage-content{
        @include flex(column, flex-start, flex-start);
        width: 1272px;
        margin: 0 auto;
        .advantage-list{
          width: 100%;
          padding: 0 24px;
          box-sizing: border-box;
          @include flex(row, space-between, center);
          .advantage-item{
            @include flex(row, center, center);
            margin: 24px 0px 24px 0;
            // &:last-child{
            //   margin-right: 0;
            // }
            img{
              width: 28px;
              height: 32px;
              margin-right: 16px;
            }
            span{
              // height: 31px;
              // font-size: $font24;
              font-weight: bold;
              color: $fontColor1E4;
              line-height: 31px;
            }
          }
        }
        .hint-box{
          border-radius: 8px 8px 0px 0px ;
          background: rgba(242, 169, 21, 0.16);
          padding: 20px 88px;
          box-sizing: border-box;
          @include flex(column, flex-start, flex-start);
          .hint-item{
            @include flex(row, flex-start, flex-start);
            margin: 4px 0;
            p{
              // font-size: $font24;
              color: $fontColor333;
              line-height: 31px;
              a{
                font-weight: bold;
                color: $fontColor447;
              }
            }
          }
        }
      }
    }
  }
  .table-container{
    @include flex(column, flex-start, flex-start);
    width: 1272px;
    margin: 0 auto;
    padding: 64px 88px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
    .table-box{
      width: 100%;
      display: flex;
      flex-direction: column;
      .table-header{
        width: 100%;
        @include flex(row, flex-start, flex-start);
        .left-title{
          width: 42%;
          height: 79px;
          span{
            margin-left: 16px;
            margin-top: 3px;
            // height: 52px;
            font-size: $font40;
            font-weight: bold;
            color: #333333;
            line-height: 52px;
          }
        }
        .right-title{
          width: 58%;
          height: 79px;
          border-radius: 8px 8px 0px 0px ;
          border: 1px solid #F2A915;
          text-align: center;
          background: $bgColorFDF;
          box-sizing: border-box;
          border-bottom: none;
          span{
            // height: 79px;
            line-height: 79px;
            font-size: 24px;
            font-weight: bold;
            color: $fontColorF2A;
          }
        }
      }
      .region-limit-box{
        @include flex(row, flex-start, flex-start);
        background: #FAFAFA;
        span{
          display: inline-block;
          margin-top: 16px;
          // height: 26px;
          // font-size: $font20;
          font-weight: bold;
          color: $fontColor333;
          line-height: 26px;
        }
        .region-limit-left{
          border: 1px solid #D3D3D3;
          border-right: none;
          span{
            margin-left: 16px;
          }
        }
        .region-limit-right{
          text-align: center;
          border: 1px solid #F2A915;
          border-bottom: 1px solid #D3D3D3;
          span{
            font-weight: 400;
          }
        }
      }
      .age-box{
        @include flex(row, flex-start, flex-start);
        background-color: $bgColorFFF;
        .age-left{
          @include flex(row, flex-start, center);
          &.age-left-special{
            @include flex(column, flex-end, flex-start);
            height: 100px;
          }
          span{
            // height: 31px;
            // font-size: $font20;
            font-weight: bold;
            color: #000;
            line-height: 31px;
          }
        }
        .age-right{
          border: 1px solid #F2A915;
          border-top: none;
          border-bottom: none;
          @include flex(column, flex-start, center);
          span{
            margin-top: 16px;
            // height: 26px;
            // font-size: $font20;
            color: $fontColor333;
            line-height: 26px;
            &:last-child{
              margin-bottom: 16px;
            }
          }
        }
      }
      .renewal-age-box{
        @include flex(row, flex-start, flex-start);
        background-color: $bgColorFAF;
        span{
          display: inline-block;
          // height: 26px;
          // font-size: $font20;
          font-weight: bold;
          color: $fontColor333;
          line-height: 26px;
        }
        .renewal-left{
          @include flex(row, flex-start, center);
          height: 116px;
          border: 1px solid #D3D3D3;
          border-right: none;
          span{
            margin-left: 16px;
          }
        }
        .renewal-right{
          @include flex(column, flex-start, flex-start);
          border: 1px solid #F2A915;
          border-top: 1px solid #D3D3D3;
          border-bottom: 1px solid #D3D3D3;
          span{
            font-weight: 400;
          }
          div{
            @include flex(column, center, center);
            text-align: center;
            &:first-child{
              background: #F0F0F0;
              border-bottom: 1px solid #D3D3D3;
            }
          }
        }
      }
      .accidental-box{
        @include flex(row, flex-start, flex-start);
        background-color: $bgColorFAF;
        span{
          display: inline-block;
          // height: 26px;
          // font-size: $font20;
          font-weight: bold;
          color: $fontColor333;
          line-height: 26px;
        }
        .left-box{
          width: 42%;
          @include flex(row, flex-start, center);
          height: 116px;
          border: 1px solid #D3D3D3;
          border-right: none;
          span{
            margin-left: 16px;
          }
        }
        .right-box{
          width: 58%;
          @include flex(column, flex-start, flex-start);
          border: 1px solid #F2A915;
          border-top: 1px solid #D3D3D3;
          border-bottom: 1px solid #D3D3D3;
          .guaranteed-amount-box{
            width: 100%;
            @include flex(row, center, center);
            height: 57px;
            &:first-child{
              background-color: #F0F0F0;
              border-bottom: 1px solid #D3D3D3;
            }
            &:last-child{
              span{
                font-weight: 400;
              }
            }
            :first-child{
              border-right: 1px solid #D3D3D3;
            }
            span{
              line-height: 57px;
              display: inline-block;
              width: 50%;
              height: 100%;
              text-align: center;
            }
          }
        }
      }
    }
    .insurance-desc{
      margin-top: 56px;
      background: $bgColorFDF;
      border-radius: 8px;
      padding: 24px;
      // font-size: $font20;
      color: $fontColor333;
      line-height: 30px;
    }
    .insurance-features{
      margin-top: 40px;
      @include flex(row, flex-start, flex-start);
      p{
        // font-size: $font20;
        color: $fontColor333;
        line-height: 30px;
      }
      .insurance-title{
        font-weight: bold;
        // font-size: $font24;
        color: $fontColor1E4;
        line-height: 31px;
      }
      .left-box{
        @include flex(column, flex-start, flex-start);
        width: 604px;
        .features-list{
          @include flex(column, flex-start, flex-start);
        }
      }
      .right-box{
        @include flex(column, flex-start, flex-start);
        margin-left: 60px;
        flex: 1;
      }
    }
    .insurance-intro{
      margin-top: 40px;
      background: $bgColorE1E;
      border-radius: 8px;
      padding: 24px;
      // font-size: $font24;
      color: $fontColor1E4;
      line-height: 31px;
    }
    .special-instructions{
      margin-top: 56px;
      // font-size: $font20;
      color: $fontColor9D9;
      line-height: 30px;
      font-style: italic
    }
  }
  .btn-group{
    @include flex(row, flex-end, center);
    width: 1272px;
    margin: 64px auto 88px;
    button{
      padding: 0 68px;
      box-sizing: border-box;
      height: 79px;
      font-size: $font24;
      text-align: center;
      border-radius: 4px;
      &:first-child{
        color: $fontColor447;
        border: 1px solid #447EDA;
      }
      &:last-child{
        padding: 0 92px;
        box-sizing: border-box;
        margin-left: 32px;
        color: $fontColorFFF;
        background: $bgColor447;
      }
    }
  }
  .important-note-box{
    background: $bgColorE1E;
    margin-top: 88px;
    .important-note-content{
      width: 1272px;
      margin: 0 auto;
      @include flex(column, flex-start, flex-start);
      padding: 20px 0;
      box-sizing: border-box;
      .important-note{
        @include flex(row, flex-start, center);
        color: $bgColor1E4;
        line-height: 30px;
        padding: 12px 0;
        box-sizing: border-box;
        a{
          color: $bgColor1E4;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
}