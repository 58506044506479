@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.news-container{
  @include flex(column, flex-start, center);
  background-color: $bgColorF7F;
  .main{
    @include flex(column, flex-start, flex-start);
    width: 1248px;
    margin-bottom: 82px;
    .news-list{
      margin-top: 24px;
      width: 100%;
      @include flex(column, flex-start, flex-start);
      .news-item{
        cursor: pointer;
        width: 100%;
        @include flex(row, flex-start, flex-start);
        padding: 32px;
        &:hover{
          background: #FFFFFF;
          box-shadow: 0px 0px 15px 1px rgba(36, 69, 122, 0.32);
        }
        img{
          border-radius: 8px;
          width: 384px;
          height: 200px;
        }
        .news-content{
          flex: 1;
          margin-left: 33px;
          @include flex(column, space-between, flex-start);
          height: 200px;
          .top{
            width: 100%;
            :first-child{
              @include ellipsis(2);
              font-size: $font24;
              font-family: $fontFamilySourceHanSansCNBold;
              font-weight: bold;
              color: $fontColor333;
              line-height: 36px;
            }
            :last-child{
              margin-top: 22px;
              @include ellipsis(2);
              font-size: $font18;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor333;
              line-height: 30px;
            }
          }
          .bottom{
            width: 100%;
            @include flex(row, flex-end, center);
            img{
              width: 22px;
              height: 22px;
            }
            p{
              margin-left: 4px;
              font-size: $font16;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor9D9;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}