@import '../../assets/scss/config.scss';
@import '../../assets/scss/mixin.scss';
.search-content-bg{
  margin-top: 200px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: $bgColorFFF;
  @include flex(column, flex-start, center);
  .search-content{
    width: 900px;
    .filter-title{
      margin-top: 8px;
      @include flex(row,  flex-start, center);
      p{
        margin-right: 6px;
        height: 16px;
        line-height: 16px;
        font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        color: $fontColor333;
      }
      img{
        width: 7px;
        height: 11px;
      }
    }
    .filter-box{
      width: 100%;
      margin-top: 15px;
      @include flex(column,  flex-start, flex-start);
      border: 1px solid #D3D3D3;
      border-radius: 8px;
      padding: 0px 16px;
      box-sizing: border-box;
      background-color: $fontColorFFF;
      position: relative;
      .select-insurance-company{
        width: 100%;
        @include flex(column,  flex-start, flex-start);
        border-bottom: 1px solid #D3D3D3;
        .company-box{
          @include flex(row,  flex-start, flex);
          line-height: 16px;
          // font-size: $font16;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          padding-bottom: 4px;
          width: 100%;
          p{
            margin-top: 12px;
            width: 80px;
            height: 16px;
            margin-left: 7px;
            margin-right: 40px;
            color: $fontColor9D9;
          }
          .species-list{
            flex: 1;
            @include flex(row,  flex-start, center);
            flex-wrap: wrap;
            .species-menu-box{
              cursor: pointer;
              @include flex(row,  center, center);
              margin: 12px 40px 12px 0;
              &:last-child{
                margin-right: 0px;
              }
              img{
                width: 18px;
                height: 18px;
                margin-right: 4px;
              }
              .species-menu{
                user-select: none;
                cursor: pointer;
                height: 16px;
                color: $fontColor333;
                &.species-menu-active{
                  color: $fontColor447;
                }
              }
            }
          }
          .more-btn{
            cursor: pointer;
            margin-top: 12px;
            width: 44px;
            height: 20px;
            border: 1px solid #447EDA;
            line-height: 20px;
            text-align: center;
            // font-size: $font14;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColor447;
          }
        }
        .select-box{
          width: 100%;
          margin-top: 12px;
          margin-bottom: 16px;
          @include flex(row, center, center);
          line-height: 28px;
          // font-size: $font14;
          font-family: $fontFamilySourceHanSansCNMedium;
          font-weight: 500;
          color: $fontColorFFF;
          span{
            cursor: pointer;
            text-align: center;
            background: $bgColor447;
            width: 44px;
            height: 28px;
          }
          :last-child{
            margin-left: 32px;
            color: $fontColor333;
            border: 1px solid #9D9D9D;
            background: $bgColorFFF;
          }
        }
      }
      .currency-box{
        @include flex(row,  flex-start, flex-start);
        line-height: 16px;
        // font-size: $font16;
        font-family: $fontFamilySourceHanSansCNMedium;
        font-weight: 500;
        width: 100%;
        padding-top: 16px;
        border-bottom: 1px solid #D3D3D3;
        &:last-child{
          border-bottom: none;
        }
        p{
          width: 80px;
          height: 16px;
          margin-left: 8px;
          margin-right: 36px;
          color: $fontColor9D9;
        }
        .currency-list{
          flex: 1;
          @include flex(row,  flex-start, center);
          flex-wrap: wrap;
          .currency-menu-box{
            margin-right: 40px;
            margin-bottom: 16px;
            &:last-child{
              margin-right: 0px;
            }
            .currency-menu{
              user-select: none;
              cursor: pointer;
              height: 16px;
              color: $fontColor333;
              &.currency-menu-active{
                color: $fontColor447;
              }
            }
          }
        }
      }
    }
    .sort-box{
      @include flex(row,  flex-start, center);
      display: none;
      width: 100%;
      margin-top: 16px;
      background: $bgColorFAF;
      height: 48px;
      border: 1px solid #D3D3D3;
      .default-sort{
        @include flex(row, center, center);
        cursor: pointer;
        padding: 0 24px;
        height: 48px;
        box-sizing: border-box;
        color: $fontColor9D9;
        &:hover{
          color: $fontColor447;
          border: 1px solid #447EDA;
        }
        &.default-sort-selected{
          color: $fontColorFFF;
          background-color: $bgColor447;
        }
        span{
          height: 16px;
          line-height: 16px;
          font-size: $fontFamilySourceHanSansCNMedium;
          font-family: Source Han Sans CN;
          font-weight: 500;
        }
        img{
          // width: 12px;
          // height: 8px;
          margin-left: 4px;
        }
      }
    }
    .search-result-title{
      display: none;
      margin-top: 23px;
      height: 20px;
      font-size: $font20;
      font-family: $fontFamilySourceHanSansCNMedium;
      font-weight: 400;
      color: $fontColor333;
      line-height: 20px;
    }
    .filter-result-no-category {
      color: $fontColor333;
      font-family: $fontFamilySourceHanSansCNRegular;
      margin-top: 25px;
      span:nth-child(2) {
        color: $fontColor447;
      }
    }
    .category-box{
      margin: -44px -98px -60px -98px;
      width: 1096px;
      height: 500px;
      border-radius: 8px;
      background-color: $bgColorEBE;
      z-index: -10;
      color: $fontColor333;
      font-family: $fontFamilySourceHanSansCNRegular;
      @include flex(row, center, flex-start);
      .category-content {
        width: 900px;
        .filter-result {
          margin-top: 67px;
          span:nth-child(2) {
            color: $fontColor447;
          }
        }
        .category-title {
          margin-top: 75px;
          height: 35px;
          line-height: 35px;
          @include flex(row, center);
          img {
            height: 30px;
          }
          span {
            margin-left: 8px;
            color: $fontColor447;
            font-family: $fontFamilySourceHanSansCNBold;
          }
        }
        .category-item-title {
          @include flex(row, center);
          margin-top: 20px;
          line-height: 58px;
          font-family: $fontFamilySourceHanSansCNBold;
        }
        .category-item-sub-title {
          @include flex(row, center);
          height: 36px;
          line-height: 36px;
          font-family: $fontFamilySourceHanSansCNBold;
        }
        .category-item-info {
          @include flex(row, center);
          line-height: 36px;
          :first-child {
            width: 12px;
            height: 12px;
            background: $bgColor447;
          }
          :last-child {
            padding-left: 4px;
            @include ellipsis(1);
          }
        }
      }
    }
    .search-result{
      margin-top: 23px;
      @include flex(column,  flex-start, flex-start);
      .search-result-item{
        width: 100%;
        @include flex(column,  flex-start, flex-start);
        border: 1px solid #D3D3D3;
        margin-top: 16px;
        &:hover{
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.32);
        }
        &:first-child{
          margin-top: 0;
        }
        .top{
          @include flex(row, space-between, flex-start);
          padding: 22px 24px 0;
          box-sizing: border-box;
          width: 100%;
          .left{
            flex: 1;
            @include flex(row, flex-start, flex-start);
            img{
              margin-top: 2px;
              width: 127px;
              height: 77px;
              margin-right: 16px;
            }
            p{
              @include ellipsis(2);
              line-height: 22px;
              font-size: $font20;
              font-family: $fontFamilySourceHanSansCNRegular;
              font-weight: 400;
              color: $fontColor333;
            }
          }
          .price{
            margin-left: 50px;
            margin-top: 28px;
            height: 26px;
            line-height: 26px;
            font-size: $font26;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColor447;
          }
        }
        .tag-box{
          width: 100%;
          padding: 16px 24px 0;
          box-sizing: border-box;
          @include flex(row,  flex-start, center);
          flex-wrap: wrap;
          span{
            text-align: center;
            background-color: $bgColorFCE;
            padding: 7px 8px;
            box-sizing: border-box;
            font-size:$font20;
            font-family: $fontFamilySourceHanSansCNRegular;
            font-weight: 400;
            color: $fontColor333;
            line-height: 19px;
            margin-right: 8px;
            &:last-child{
              margin-right: 0;
            }
          }
        }
        .bottom{
          width: 100%;
          margin-top: 16px;
          @include flex(row, space-between, flex-start);
          background: $bgColorFFFA;
          padding: 24px 24px 36px;
          .left{
            flex: 1;
            @include flex(row, flex-start, center);
            .desc-box{
              // width: 32%;
              @include flex(column, center, center);
              font-family: $fontFamilySourceHanSansCNMedium;
              font-weight: 400;
              margin-right: 2%;
              &:last-child{
                margin-right: 0px;
              }
              :first-child{
                @include ellipsis();
                height: 19px;
                line-height: 19px;
                font-size: $font20;
                color: $fontColor447;
              }
              :last-child{
                @include ellipsis();
                margin-top: 14px;
                height: 16px;
                line-height: 16px;
                font-size: $font16;
                color: $fontColor333;
              }
            }
          }
          .check-detail{
            margin-left: 2%;
            cursor: pointer;
            width: 120px;
            height: 46px;
            line-height: 46px;
            background: $fontColorF2A;
            text-align: center;
            font-size: $font14;
            font-family: $fontFamilySourceHanSansCNMedium;
            font-weight: 500;
            color: $fontColorFFF;
          }
        }
      }
    }
  }
}